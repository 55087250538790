import Vue from 'vue'
import Vuex from 'vuex'

import client from '@/client'
import colors from '@/data/colors'

Vue.use(Vuex)

export const store = new Vuex.Store({
  state: {
    models: [],
    products: [],
    categories: [],
    colors: colors,
    // when a configuration is pre loaded from the API, we need to temporarily freeze update processes to not overwrite components
    isPreLoadedConfiguration: undefined,
    // freeze a configuration and prevent updates. useful if preloaded configuration should show up
    isFrozen: false,
    formData: {},
    requestId: undefined,
    // currents:
    currentIndividualName: undefined,
    currentCategory: undefined,
    currentModel: undefined,
    currentGeometry: undefined,
    currentHeight: undefined,
    currentColorBase: {},
    currentFrameColor: {},
    currentLogoColor: {},
    // components
    currentComponents: {
      basics: {
        gearshift: null,
        drive: null,
        driveVariation: null,
        battery: null,
        display: null,
        brakes: null,
        headset: null,
        fork: null
      },
      options: {
        wheelset: null,
        rims: null,
        spokes: null,
        hubs: null,
        wheels: null,
        wheelsVariation: null,
        stem: null,
        stemVariation: null,
        handlebarType: null,
        handlebars: null,
        handlebarsVariation: null,
        grips: null,
        gripsVariation: null,
        handlebarTape: null,
        handlebarTapeVariation: null,
        pedals: null,
        pedalsVariation: null,
        saddle: null,
        saddleVariation: null,
        seatPost: null,
        seatPostVariation: null
      },
      accessoires: {
        stvoBasic: null,
        mudguards: null,
        luggageCarrier: null,
        headlights: null,
        headlightsVariation: null,
        backlights: null,
        bell: null,
        stand: null,
        chainGuard: null,
        lock: null,
        lockVariation: null,
        bikeComputer: null,
        batteryLight: null,
        panniers: null,
        panniersVariation: null,
        bottleCage: null,
        bottleCageVariation: null,
        hubDynamo: null
      }
    },
    currentReducedComponents: {
      basics: {
        gearshift: null,
        drive: null,
        driveVariation: null,
        battery: null,
        display: null,
        brakes: null,
        headset: null,
        fork: null
      },
      options: {
        wheelset: null,
        rims: null,
        spokes: null,
        hubs: null,
        wheels: null,
        wheelsVariation: null,
        stem: null,
        stemVariation: null,
        handlebarType: null,
        handlebars: null,
        handlebarsVariation: null,
        grips: null,
        gripsVariation: null,
        handlebarTape: null,
        handlebarTapeVariation: null,
        pedals: null,
        pedalsVariation: null,
        saddle: null,
        saddleVariation: null,
        seatPost: null,
        seatPostVariation: null
      },
      accessoires: {
        stvoBasic: null,
        mudguards: null,
        luggageCarrier: null,
        headlights: null,
        headlightsVariation: null,
        backlights: null,
        bell: null,
        stand: null,
        chainGuard: null,
        lock: null,
        lockVariation: null,
        bikeComputer: null,
        batteryLight: null,
        panniers: null,
        panniersVariation: null,
        bottleCage: null,
        bottleCageVariation: null,
        hubDynamo: null
      }
    }
  },
  mutations: {
    setIsPreLoadedConfiguration (state, value) {
      state.isPreLoadedConfiguration = value
    },
    setIsFrozen (state, value) {
      // freeze updates here
      state.isFrozen = value
    },
    setConfigurationFromOrderRequest (state, resource) {

      const configuration = resource.configuration

      state.requestId = resource.request_id
      state.currentIndividualName = resource.name
      state.formData = resource.form_data
      state.isPreLoadedConfiguration = true

      state.currentCategory = configuration.category
      state.currentModel = configuration.model
      state.currentGeometry = configuration.geometry
      state.currentHeight = configuration.height
      state.currentColorBase = configuration.colorBase
      state.currentFrameColor = configuration.frameColor
      state.currentLogoColor = configuration.logoColor
      state.currentComponents = configuration.components
      state.currentReducedComponents = configuration.reducedComponents
    },
    setProducts (state, products) {
      state.products = products
    },
    setModels (state, models) {
      state.models = models
    },
    setCategories (state, categories) {
      state.categories = categories
    },
    // currents:
    setCurrentCategory(state, category) {
      state.currentCategory = category
    },
    setCurrentModel(state, model) {
      state.currentModel = model
    },
    setCurrentColorBase (state, color) {
      state.currentColorBase = color
    },
    setCurrentFrameColor (state, color) {
      state.currentFrameColor = color
    },
    setCurrentLogoColor (state, color) {
      state.currentLogoColor = color
    },
    setCurrentHeight (state, height) {
      state.currentHeight = height
    },
    setCurrentGeometry(state, geometry) {
      state.currentGeometry = geometry
    },
    // meta -- sets component based on payload
    setComponent (state, payload) {
      if (payload) {
        state.currentComponents[payload.category][payload.type] = payload.value
        // this is needed for filtering dependencies - a clean object with only name and SKU

        if (payload.value) {
          state.currentReducedComponents[payload.category][payload.type] = {
            name: payload.value.name,
            sku: payload.value.sku
          }
        } else {
          state.currentReducedComponents[payload.category][payload.type] = null
        }
      }
    },
  },
  getters: {
    getModelsByCategory (state) {
      return state.models.filter(prod => prod.categoryIds.includes(state.currentCategory.woo_id))
    },
    getCurrentConfiguration (state) {
      return {
        category: state.currentCategory,
        model: state.currentModel,
        height: state.currentHeight,
        geometry: state.currentGeometry,
        components: state.currentComponents,
        reducedComponents: state.currentReducedComponents,
        colorBase: state.currentColorBase,
        frameColor: state.currentFrameColor,
        logoColor: state.currentLogoColor
      }
    },
    getColorsForModel: (state) => (categoryColorsId) => {
      return state.products.filter(prod => prod.includedIn.includes(`${state.currentModel.sku}`) && prod.categoryIds.includes(categoryColorsId))
    },
    getComponentForModelByCategoryId: (state) => (categoryId) => {
      return state.products.filter(prod => prod.includedIn.includes(`${state.currentModel.sku}`) && prod.categoryIds.includes(categoryId))
    },
    getTotalPrice (state) {

      // calculate sum of component prices
      let arr = Object.values(state.currentComponents)

      const totalPriceComponents = arr.reduce((objSum, obj) => {
        const arr = Object.values(obj);

        const itemsTotal = arr.reduce((itemSum, item) => {
          if (!item) {
            return itemSum;
          }
          return item.customPrice ? itemSum + item.customPrice : itemSum;
        }, 0);

        return objSum + itemsTotal;
      }, 0);

      // sum up model, components and frame color price
      if (state.currentModel) {
        return parseFloat(state.currentModel.regularPrice) + totalPriceComponents + state.currentFrameColor.customPrice
      } else {
        return 0
      }
    },

    // TODO calculate totalWeight here
    getTotalWeight (state) {
      // calculate sum of component prices
      let arr = Object.values(state.currentComponents)

      const totalWeightComponents = arr.reduce((objSum, obj) => {
        const arr = Object.values(obj);

        const itemsTotal = arr.reduce((itemSum, item) => {
          if (!item) {
            return itemSum;
          }
          return item.weight ? itemSum + item.weight : itemSum;
        }, 0);

        return objSum + itemsTotal;
      }, 0);

      // sum up model, components and frame color price
      if (state.currentModel) {
        return parseFloat(state.currentModel.weight) + totalWeightComponents
      } else {
        return 0
      }
    }
  },
  actions: {
    async setCategories (context) {
      try {
        let data = await client.get('/categories')

        data = data['data']['resources']

        if (data.length > 0) {
          context.commit('setCategories', data)
        }
      } catch (error) {
        console.log(error)
      }
    },
    async setProducts (context) {
      let products = []

      try {
        let data = await client.get('/products')

        data = data['data']['resources']

        if (data) {
          data.forEach(data => {
            let deliveryTime = ''
            if (data.meta_data.delivery_time) {
              deliveryTime = data.meta_data.delivery_time.name
            }
            
            // product images
            let imageMask = undefined
            if (data.meta_data.attributes.filter(el => el.name === 'imageMask')[0]) {
              imageMask = data.meta_data.attributes.filter(el => el.name === 'imageMask')[0].options[0]
            }
            let imageParts = undefined
            if (data.meta_data.attributes.filter(el => el.name === 'imageParts')[0]) {
              imageParts = data.meta_data.attributes.filter(el => el.name === 'imageParts')[0].options[0]
            }
            let imageFrame = undefined
            if (data.meta_data.attributes.filter(el => el.name === 'imageFrame')[0]) {
              imageFrame = data.meta_data.attributes.filter(el => el.name === 'imageFrame')[0].options[0]
            }
            let imageStvo = undefined
            if (data.meta_data.attributes.filter(el => el.name === 'imageStvo')[0]) {
              imageStvo = data.meta_data.attributes.filter(el => el.name === 'imageStvo')[0].options[0]
            }

            // check for other images and set it where it belongs to
            let otherImages = []
            let showOtherImageFor = undefined
            if (data.meta_data.images.filter(el => el.name != 'mask' && el.name != 'frame' && el.name != 'parts' && el.name != 'stvo')[0]) {
              otherImages = data.meta_data.images.filter(el => el.name != 'mask' && el.name != 'frame' && el.name != 'parts' && el.name != 'stvo')
            }

            // product attributes
            let productType = ''
            if (data.meta_data.attributes.filter(el => el.name === 'productType')[0]) {
              productType = data.meta_data.attributes.filter(el => el.name === 'productType')[0].options[0]
            }

            let suspensionFork = false
            if (data.meta_data.attributes.filter(el => el.name === 'suspensionFork')[0]) {
              suspensionFork = data.meta_data.attributes.filter(el => el.name === 'suspensionFork')[0].options[0] == 'true'
            }

            let rigidFork = false
            if (data.meta_data.attributes.filter(el => el.name === 'rigidFork')[0]) {
              rigidFork = data.meta_data.attributes.filter(el => el.name === 'rigidFork')[0].options[0] == 'true'
            }

            let frameHeightsM = []
            if (data.meta_data.attributes.filter(el => el.name === 'frameHeightsM')[0]) {
              frameHeightsM = data.meta_data.attributes.filter(el => el.name === 'frameHeightsM')[0].options
            }

            let frameHeightsW = []
            if (data.meta_data.attributes.filter(el => el.name === 'frameHeightsW')[0]) {
              frameHeightsW = data.meta_data.attributes.filter(el => el.name === 'frameHeightsW')[0].options
            }

            let frameHeightsWave = []
            if (data.meta_data.attributes.filter(el => el.name === 'frameHeightsWave')[0]) {
              frameHeightsWave = data.meta_data.attributes.filter(el => el.name === 'frameHeightsWave')[0].options
            }

            let customPrice = 0.0
            if (data.meta_data.attributes.filter(el => el.name === 'customPrice')[0]) {
              customPrice = parseFloat(data.meta_data.attributes.filter(el => el.name === 'customPrice')[0].options[0])
            }

            // additional attributes for colors
            let hexCode = undefined
            if (data.meta_data.attributes.filter(el => el.name === 'hexCode')[0]) {

              hexCode = data.meta_data.attributes.filter(el => el.name === 'hexCode')[0].options[0]
            }

            let ralCode = undefined
            if (data.meta_data.attributes.filter(el => el.name === 'ralCode')[0]) {
              ralCode = data.meta_data.attributes.filter(el => el.name === 'ralCode')[0].options[0]
            }

            let isDefault = false
            if (data.meta_data.attributes.filter(el => el.name === 'isDefault')[0]) {
              isDefault = data.meta_data.attributes.filter(el => el.name === 'isDefault')[0].options[0] == '1'
            }

            // only define colors when there is at least a hexCode available
            let colors = undefined
            if (hexCode) {
              colors = {
                hexCode: hexCode,
                ralCode: ralCode,
                customPrice: customPrice
              }
            }

            // attributes to connect product with other products
            let baseOf = []
            if (data.meta_data.attributes.filter(el => el.name === 'baseOf')[0]) {
              baseOf = data.meta_data.attributes.filter(el => el.name === 'baseOf')[0].options
            }

            let includedIn = []
            if (data.meta_data.attributes.filter(el => el.name === 'includedIn')[0]) {
              includedIn = data.meta_data.attributes.filter(el => el.name === 'includedIn')[0].options
            }

            let dependsOn = undefined
            if (data.meta_data.attributes.filter(el => el.name === 'dependsOn')[0]) {
              dependsOn = data.meta_data.attributes.filter(el => el.name === 'dependsOn')[0].options
            }

            // variations
            let variations = []

            if (data.variations) {
              variations = data.variations
            }

            let product = {
              id: data.woo_id,
              sku: data.sku,
              name: data.name,
              menuOrder: data.menu_order,
              description: data.description,
              regularPrice: data.price,
              customPrice: customPrice,
              weight: data.weight,
              shortDescription: data.short_description,
              baseOf: baseOf,
              includedIn: includedIn,
              dependsOn: dependsOn,
              link: data.permalink,
              categoryIds: [data.category_id],
              productType: productType,
              suspensionFork: suspensionFork,
              rigidFork: rigidFork,
              frameHeightsM: frameHeightsM,
              frameHeightsW: frameHeightsW,
              frameHeightsWave: frameHeightsWave,
              imageMask: imageMask,
              imageParts: imageParts,
              imageFrame: imageFrame,
              imageStvo: imageStvo,
              otherImages: otherImages,
              showOtherImageFor: showOtherImageFor,
              upsellIds: data.upsell_ids,
              variations: variations,
              hasInfoBoxOpen: false,
              isDefault: isDefault,
              colors: colors,
              deliveryTime: deliveryTime
            }

            products.push(product)
          })

          if (products.length > 0) {
            context.commit('setProducts', products)
            // models
            context.commit('setModels', products.filter(prod => prod.productType === 'bikeModel'))
          }
        }
      } catch (error) {
        console.log(error)
      }
    }
  }
})

export default store
