<template>
  <div v-if="currentModel">
    <choose-component-by-select
      label="STVO Basisausstattung"
      :category-id="293"
      category="accessoires"
      category-type="stvoBasic"
      select-type="radio"
    />

    <choose-component-by-select
      label="Schutzbleche"
      :category-id="294"
      category="accessoires"
      category-type="mudguards"
      @updateKey="mudguardsKey = Date.now()"
      :key="mudguardsKey"
    />

    <choose-component-by-select
      label="Gepäckträger"
      :category-id="295"
      category="accessoires"
      category-type="luggageCarrier"
      @updateKey="luggageCarrierKey = Date.now()"
      :key="luggageCarrierKey"
    />

    <choose-component-by-select
      label="Nabendynamo"
      :category-id="306"
      category="accessoires"
      category-type="hubDynamo"
      @updateKey="hubDynamoKey = Date.now()"
      :key="hubDynamoKey"
      v-if="!exceptionIncluded('3110')"
      :multidimensional-dependencies="true"
    />

    <choose-component-by-select
      label="Scheinwerfer"
      :category-id="296"
      category="accessoires"
      category-type="headlights"
      variation-type="headlightsVariation"
      @updateKey="headlightsKey = Date.now()"
      :key="headlightsKey"
    />

    <choose-component-by-select
      label="Rücklicht"
      :category-id="297"
      category="accessoires"
      category-type="backlights"
      @updateKey="backlightsKey = Date.now()"
      :key="backlightsKey"
    />

    <choose-component-by-select
      label="Glocke"
      :category-id="318"
      category="accessoires"
      category-type="bell"
    />

    <choose-component-by-select
      label="Ständer"
      :category-id="298"
      category="accessoires"
      category-type="stand"
      @updateKey="standKey = Date.now()"
      :key="standKey"
    />

    <choose-component-by-select
      label="Kettenschutz"
      :category-id="299"
      category="accessoires"
      category-type="chainGuard"
    />

    <choose-component-by-select
      label="Schloss"
      :category-id="300"
      category="accessoires"
      category-type="lock"
      variation-type="lockVariation"
    />

    <choose-component-by-select
      label="Fahrradcomputer"
      :category-id="301"
      category="accessoires"
      category-type="bikeComputer"
    />

    <choose-component-by-select
      label="Batterielicht"
      :category-id="302"
      category="accessoires"
      category-type="batteryLight"
    />

    <choose-component-by-select
      label="Gepäcktasche"
      :category-id="304"
      category="accessoires"
      category-type="panniers"
      variation-type="panniersVariation"
    />

    <choose-component-by-select
      label="Flaschenhalter"
      :category-id="305"
      category="accessoires"
      category-type="bottleCage"
      variation-type="bottleCageVariation"
    />
  </div>
</template>

<script>
import ChooseComponentBySelect from '@/components/ChooseComponentBySelect'
export default {
  components: { ChooseComponentBySelect },
  data () {
    return {
      mudguardsKey: Date.now(),
      luggageCarrierKey: Date.now() + 1,
      headlightsKey: Date.now() + 2,
      backlightsKey: Date.now() + 3,
      hubDynamoKey: Date.now() + 4,
      standKey: Date.now() + 5,
    }
  },
}
</script>

<style lang="css" scoped>
</style>
