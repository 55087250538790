<template>
  <div class="">
    <svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    	 viewBox="0 0 893.1 74.4" style="enable-background:new 0 0 893.1 74.4;" class="layer" xml:space="preserve"
       :style="{
         fill: currentLogoColor.hexCode,
         left: `${leftPosition}%`,
         top: `${topPosition}%`,
         height: `${logoHeight}px`,
         transform: `rotate(${inclinationDegree}deg)`
        }">
      <g>
      	<path class="st0" d="M207.6,56c-1.7,0-3.4-0.3-4.9-1c-1.6-0.7-2.9-1.6-4.1-2.7c-1.1-1.1-2-2.5-2.7-4c-0.6-1.5-1-3.2-1-4.9V5.1
      		h-14.3v38.2c0,3.7,0.7,7.2,2.1,10.5c1.4,3.3,3.3,6.2,5.8,8.6c2.5,2.5,5.4,4.4,8.6,5.8c3.3,1.4,6.8,2.1,10.5,2.1h30.2V56
      		C237.8,56,207.6,56,207.6,56z"/>
      	<path class="st0" d="M325.6,21.9c-1.4-3.3-3.4-6.3-5.9-8.7c-2.5-2.5-5.4-4.5-8.7-5.9c-3.3-1.4-6.8-1.9-10.8-2.2
      		c0,0-10.8-0.3-14.4-0.3c-3.5,0-14.2,0.3-14.2,0.3c-3.8,0.3-7.4,0.7-10.8,2.2c-3.3,1.4-6.3,3.4-8.7,5.9c-2.5,2.5-4.5,5.4-5.9,8.7
      		s-2.2,6.9-2.2,10.7v10.3c0,3.8,0.7,7.4,2.2,10.8c1.4,3.3,3.4,6.3,5.9,8.7c2.5,2.5,5.4,4.5,8.7,5.9c3.3,1.4,7,2.2,10.8,2.2h28.6
      		c3.8,0,7.4-0.7,10.8-2.2c3.3-1.4,6.3-3.4,8.7-5.9c2.5-2.5,4.5-5.4,5.9-8.7c1.4-3.3,2.2-7,2.2-10.8V32.5
      		C327.8,28.8,327,25.2,325.6,21.9z M300.2,56h-28.6c-1.8,0-3.5-0.3-5.1-1s-3.1-1.5-4.3-2.7c-1.2-1.2-2.3-2.8-3-4.4s-0.9-3.3-0.9-5.1
      		c0-2.5,0-7.7,0-10.3c0-1.7,0.4-3.7,1.1-5.3c0.7-1.6,1.6-2.9,2.7-4.1c1.2-1.2,2.8-2.1,4.4-2.8c1.6-0.7,3.3-1,5.1-1h28.6
      		c1.8,0,3.5,0.3,5.1,1s3,1.6,4.2,2.8c1.2,1.2,2.4,2.6,3.1,4.2s0.9,3.4,0.9,5.1c0,2.9,0,7.8,0,10.3c0,1.8-0.2,3.7-0.9,5.2
      		c-0.7,1.6-1.9,3.1-3.1,4.3c-1.2,1.2-2.7,2-4.3,2.7C303.7,55.7,302,56,300.2,56z"/>
      	<path class="st0" d="M584,49.1c-1-2.8-2.4-5.4-4.1-7.7c-1.6-2.1-3-3.7-6.3-6c-5.8-3.9-17.2-3.7-23.4-3.7l21-26.6h-16.1l0,0H554
      		l-21,26.6h-12.3V5.1h-14.3v65.2h14.3V44.8h37.8c1.7,0,3.4,0.3,4.9,1c1.8,0.8,3.3,1.8,4.3,2.8c1.2,1.1,1.9,2.1,2.5,3.7
      		c0.7,1.5,1,3.8,1,5.5v12.6h14.3V58C585.5,54.9,585,51.9,584,49.1z"/>
      	<path class="st0" d="M621.5,56c-1.8,0-3.4-0.3-4.9-1s-2.9-1.6-4.1-2.7c-1.1-1.1-2-2.5-2.7-4c-0.6-1.5-1-3.2-1-4.9v-15h42.3V14.1
      		h-42.3v-9h-14.3v38.2c0,3.7,0.7,7.2,2.1,10.5c1.4,3.3,3.3,6.2,5.8,8.6c2.5,2.5,5.4,4.4,8.6,5.8c3.3,1.4,6.8,2.1,10.5,2.1h51.2V56
      		C672.7,56,621.5,56,621.5,56z"/>
      	<path class="st0" d="M742.6,5.1V56h-35.7c-1.7,0-3.3-0.3-4.8-1s-2.9-1.6-4-2.8c-1.1-1.2-2-2.5-2.7-4.1c-0.6-1.5-1-3.2-1-4.9V5.1
      		h-14.3v38.2c0,3.7,0.7,7.2,2.1,10.5c1.4,3.3,3.4,6.2,5.8,8.6c2.4,2.4,5.3,4.4,8.5,5.8c3.3,1.4,6.8,2.2,10.5,2.2h49.8V5.1H742.6z"/>
      	<path class="st0" d="M886.4,21.7c-1.4-3.3-3.4-6.2-5.8-8.6c-2.4-2.4-5.3-4.4-8.5-5.8c-3.3-1.4-6.8-2.2-10.5-2.2h-89.4v65.2h14.3
      		V19.4h34.1v20.7v30.2h14.3V41.1V19.4l27-0.1c1.7,0,3.2,0.4,4.7,1c1.5,0.7,2.9,1.6,4,2.8c1.1,1.2,2.1,2.5,2.7,4.1c0.6,1.5,1,3.2,1,5
      		v38.1h14.3V32.2C888.6,28.5,887.8,25,886.4,21.7z"/>
      	<path class="st0" d="M359,23.2c1.1-1.1,2.5-2.1,4.1-2.7c1.5-0.7,3.2-1,4.9-1h42.4V5.1h-42.2c-3.7,0-7.2,0.7-10.5,2.1
      		c-3.2,1.4-6.1,3.4-8.6,5.8c-2.4,2.4-4.4,5.3-5.8,8.6s-2.1,6.8-2.1,10.5v38.2h14.3V44.8h34.7v-13h-34.7c0-0.9,0.1-3.2,0.8-4.6
      		C357.1,25.7,357.9,24.3,359,23.2z"/>
      	<path class="st0" d="M490.2,21.6c-1.4-3.3-3.3-6.2-5.8-8.6c-2.5-2.5-5.4-4.4-8.6-5.8c-3.3-1.4-6.8-2.1-10.5-2.1l-8.6-0.2H451
      		l-8.4,0.2c-3.7,0-7.2,0.7-10.5,2.1c-3.2,1.4-6.1,3.4-8.6,5.8c-2.4,2.4-4.4,5.3-5.8,8.6s-2.1,6.8-2.1,10.5v38.2h14.3V44.8h14.7h18.8
      		H478v25.5h14.3V32.1C492.4,28.5,491.6,24.9,490.2,21.6z M470.5,20.4c1.6,0.7,2.9,1.6,4.1,2.7c1.1,1.1,2,2.5,2.7,4
      		c0.4,0.9,0.8,3.6,0.8,4.6h-14.6h-33.4c0-1,0.4-3.8,0.8-4.6c0.7-1.6,1.6-2.9,2.7-4s2.5-2.1,4.1-2.7c1.5-0.7,3.2-1,4.9-1h8.7h5.7h8.7
      		C467.3,19.4,469,19.8,470.5,20.4z"/>
      	<path class="st0" d="M107.9,21.3c0.9-0.9,3.5-1.9,5.2-1.9h54.5V5.1H112c-2.4,0-4.7,0.4-7,1.2s-4.5,1.9-6.4,3.5
      		c-1.9,1.5-3.6,3.4-4.8,5.6c-1.3,2.3-2,4.9-2,7.8v17.1l0,0v12.2c0.1,2.8,0.7,5.4,2,7.7c1.2,2.2,2.8,4.1,4.8,5.6
      		c1.9,1.5,4.1,2.7,6.4,3.5c2.3,0.8,4.7,1.2,7,1.2h55.6V57.1H113c-1.7,0-4.2-0.6-5.1-1.2c-1-0.7-1.9-1.3-1.9-4.8v-6.3h34.5v-5.3v-4.1
      		v-3.6H106c0,0,0-4.9,0-6.2C106.1,23.3,107,22.2,107.9,21.3z"/>
      	<path class="st0" d="M80.7,8.5V8.3c0-0.6,0-2.4,0-2.4V5.4H64.8v0.5c0,0,0,1.9,0,2.5c0.1,7.3-0.5,22.6-2.3,27.3
      		c-1.1,2.9-7.2,8-12.7,12.5c-2.3,1.9-4.4,3.6-6.2,5.3l-1,0.9l-1-0.9c-1.7-1.5-3.7-3.2-5.7-4.9c-5.7-4.8-12.1-10.2-13.2-13.1
      		c-1.9-5.1-2.2-24.8-2.2-27.1c0-0.6,0-2.5,0-2.5V5.4H4.6v0.5c0,0,0,1.8,0,2.4v0.3c0,8.6,0.1,23.8,1.9,29c3,9,21.2,24.3,28,30.1
      		c1,0.9,1.8,1.5,2.2,1.8l0.9,0.8h10.2l0.9-0.8c0.4-0.3,1.1-1,2.2-1.8c6.8-5.8,25-21.1,28-30.1C80.7,32.1,80.8,15.4,80.7,8.5z"/>
      </g>
    </svg>

    <!-- <div class="orchestrate-box">
      <input type="number" class="input" v-model="leftPosition" step="0.1">
      <input type="number" class="input" v-model="topPosition" step="0.1">
      <input type="number" class="input" v-model="inclinationDegree" step="0.1">
      <input type="text" class="input" :value="`&quot;${this.currentModel.sku}&quot;:{ &quot;leftPosition&quot;: ${leftPosition}, &quot;topPosition&quot;: ${topPosition}, &quot;inclinationDegree&quot;: ${inclinationDegree}}`">
    </div> -->
  </div>

</template>

<script>
import logoPosition from '@/data/logoPosition'
export default {
  props: {
    bikePreviewWidth: Number,
    bikePreviewHeight: Number
  },
  data () {
    return {
      leftPosition: 47,
      topPosition: 48,
      inclinationDegree: -55
    }
  },
  mounted () {
    this.setLogoPosition()
  },
  computed: {
    currentLogoColor () {
      return this.$store.state.currentLogoColor
    },
    logoHeight () {
      const fontRatio = 75
      return this.bikePreviewWidth / fontRatio
    }
  },
  methods: {
    setLogoPosition () {
      let logoData = logoPosition[this.currentModel.sku]
      if (logoData) {
        this.leftPosition = logoData.leftPosition
        this.topPosition = logoData.topPosition
        this.inclinationDegree = logoData.inclinationDegree
      }
    }
  },
  watch: {
    currentModel () {
      this.setLogoPosition()
    }
  }
}
</script>

<style lang="sass" scoped>
  svg.layer
    position: absolute
</style>
