// Position of the logo by bike model SKU

export default {
  "110":{ "leftPosition": 47.1, "topPosition": 46.8, "inclinationDegree": -53.2},
  "111":{ "leftPosition": 45.6, "topPosition": 45.1, "inclinationDegree": -54.4},
  "120":{ "leftPosition": 45.6, "topPosition": 46.3, "inclinationDegree": -54.5},
  "121":{ "leftPosition": 45, "topPosition": 46, "inclinationDegree": -58.2},
  "122":{ "leftPosition": 45.6, "topPosition": 45.1, "inclinationDegree": -50.6},
  "140":{ "leftPosition": 45, "topPosition": 47.6, "inclinationDegree": -54.5},
  "210":{ "leftPosition": 47, "topPosition": 47.5, "inclinationDegree": -52.9},
  "220":{ "leftPosition": 47, "topPosition": 47.2, "inclinationDegree": -54.2},
  "230":{ "leftPosition": 47, "topPosition": 47.3, "inclinationDegree": -55},
  "240":{ "leftPosition": 47, "topPosition": 45.8, "inclinationDegree": -55.6},
  "250":{ "leftPosition": 47, "topPosition": 48, "inclinationDegree": -55},
  "320":{ "leftPosition": 46.7, "topPosition": 48.8, "inclinationDegree": -48.6},
  "330":{ "leftPosition": 46.7, "topPosition": 53.5, "inclinationDegree": -49},
  "340":{ "leftPosition": 46.7, "topPosition": 52.6, "inclinationDegree": -51.4},
  "350":{ "leftPosition": 46.7, "topPosition": 50.6, "inclinationDegree": -49.1},
  "410":{ "leftPosition": 45.6, "topPosition": 48.2, "inclinationDegree": -50.6},
  "420":{ "leftPosition": 45.6, "topPosition": 51.3, "inclinationDegree": -49.7},
  "510":{ "leftPosition": 46.1, "topPosition": 45.2, "inclinationDegree": -58.9},
  "520":{ "leftPosition": 46.1, "topPosition": 43.8, "inclinationDegree": -58.9},
  "530":{ "leftPosition": 47.4, "topPosition": 50, "inclinationDegree": -62},
  "540":{ "leftPosition": 47.2, "topPosition": 46.4, "inclinationDegree": -63.5},
  "550":{ "leftPosition": 46.7, "topPosition": 46.4, "inclinationDegree": -60.9}

}
