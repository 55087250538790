<template>
  <div class="control">
    <label class="radio-custom">
      <input type="radio" name="colorBase" v-model="currentColorBase" :value="{ key: 'matt', name: 'Matt' }" id="matt" selected>
      <label for="matt">Matt</label>
    </label>
    <label class="radio-custom">
      <input type="radio" name="colorBase" v-model="currentColorBase" :value="{ key: 'shiny', name: 'Glänzend' }" id="shiny">
      <label for="shiny">Glänzend</label>
    </label>
  </div>
</template>

<script>
export default {
  computed: {
    currentColorBase: {
      set (base) {
        this.$store.commit("setCurrentColorBase", base)
      },
      get () {
        return this.$store.state.currentColorBase
      }
    },
  },
  mounted () {
    this.currentColorBase = { key: 'matt', name: 'Matt' }
  },
}
</script>

<style lang="css" scoped>
</style>
