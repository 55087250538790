<template>
  <div class="navigation-tabs">
    <div class="tab-container">

      <div class="is-size-3"><strong>{{ formData.name }}</strong></div>

      <div>Status: Anfrage verschickt</div>
      <div class="has-text-grey-light">{{ formData.type }}</div>
      <div class="gap" />
      <div class="button is-small" v-if="isFrozen" @click="editConfiguration()">Konfiguration bearbeiten</div>
      <div class="button has-text-dark is-success is-small" v-else @click="showOrderModal = true">Konfiguration speichern</div>

      <OrderModal
        v-if="showOrderModal"
        :edit-mode="true"
        @close="showOrderModal = false"
      />
    </div>
  </div>
</template>

<script>
import OrderModal from '@/components/OrderModal'
export default {
  components: { OrderModal },
  props: {
    formData: Object
  },
  data () {
    return {
      showOrderModal: false
    }
  },
  computed: {
    isFrozen: {
      set (val) {
        this.$store.commit('setIsFrozen', val)
      },
      get () {
        return this.$store.state.isFrozen
      }
    },
  },
  methods: {
    editConfiguration () {
      this.isFrozen = false
    }
  }
}
</script>

<style lang="sass" scoped>
@import "~@/assets/sass/utilities/initial-variables.sass"
@import "~@/assets/sass/utilities/mixins.sass"

.navigation-tabs
  background: $white
  .tabs
    margin-bottom: 0
    width: 100%
    img
      width: 35px
      height: 35px
    +mobile
      img
        width: 30px
        height: 30px
  .tab-container
    border-top: 6px solid $green
    padding: $base-gap * 3
</style>
