<template>
  <div id="app">
    <div v-if="loading">
      Konfigurator wird geladen …
    </div>
    <div v-else>
      <!-- <div class="is-size-7">Loading Time: {{ loadingTime }}</div> -->
      <top-navigation />
      <div class="container is-fluid">
        <div class="columns is-gapless">
          <div class="column is-7-desktop">
            <bike-preview
              v-if="currentModel && currentCategory"
            />
          </div>
          <div class="column is-5-desktop">
            <summary-tab
              v-if="formData"
              :form-data="formData"
            />
            <navigation-tabs v-show="!isFrozen" />
          </div>
        </div>
        <summary-container />
      </div>
    </div>
  </div>
</template>

<script>
import client from '@/client'
import TopNavigation from '@/components/TopNavigation'
import NavigationTabs from '@/components/NavigationTabs'
import SummaryTab from '@/components/SummaryTab'
import BikePreview from '@/components/BikePreview'
import SummaryContainer from '@/components/SummaryContainer'

export default {
  name: 'App',
  components: {
    TopNavigation,
    NavigationTabs,
    BikePreview,
    SummaryContainer,
    SummaryTab
  },
  data () {
    return {
      timeStart: new Date (),
      timeEnd: new Date (),
      formData: undefined
    }
  },
  computed: {
    isPreLoadedConfiguration: {
      set (val) {
        this.$store.commit('setIsPreLoadedConfiguration', val)
      },
      get () {
        return this.$store.state.isPreLoadedConfiguration
      }
    },
    isFrozen: {
      set (val) {
        this.$store.commit('setIsFrozen', val)
      },
      get () {
        return this.$store.state.isFrozen
      }
    },
    loadingTime () {
      return (this.timeEnd.getTime() - this.timeStart.getTime()) / 1000
    },
    loading () {
      let categoriesLoaded = this.$store.state.categories.length > 0
      let productsLoaded = this.$store.state.models.length > 0

      return !(categoriesLoaded && productsLoaded)
    },
  },
  mounted () {
    this.$store.dispatch('setCategories')
    this.$store.dispatch('setProducts')
  },
  methods: {
    setCategoryAndModelBasedOnUrlQuery () {
      // fetch URL param and look for "addProduct"
      let urlString = window.location.href
      var url = new URL(urlString)
      var sku = url.searchParams.get("configureModel")

      var requestId = url.searchParams.get("presetConfiguration")

      if (sku) {
        const model = this.$store.state.models.find(mod => mod.sku === sku)
        if (model) {
          const category = this.$store.state.categories.find(cat => cat.woo_id === model.categoryIds[0])
          if (category) {
            console.log("setting")
            this.currentCategory = category
            this.currentModel = model
          }
        }
      }

      if (requestId) {
        this.isPreLoadedConfiguration = true
        this.isFrozen = true
        this.fetchConfigurationFromServer(requestId)
      } else {
        this.isFrozen = false
        this.isPreLoadedConfiguration = false
      }
    },
    async fetchConfigurationFromServer (requestId) {
      try {
        let res = await client.get(`/order_requests/${requestId}`)

        if (res) {
          this.formData = res.data.resource.form_data
          console.log(res.data.resource)
          this.$store.commit('setConfigurationFromOrderRequest', res.data.resource)
        }

      } catch (error) {
        console.log("Error: " + error)
      }
    }
  },
  watch: {
    loading () {
      if (this.loading === false) {
        this.timeEnd = new Date ()

        this.setCategoryAndModelBasedOnUrlQuery()
      }
    }
  }
}
</script>
<style lang="sass">
@import "~@/assets/sass/utilities/initial-variables.sass"
#app
  background: $white-ter
</style>
