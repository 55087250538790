import Vue from 'vue'
import App from './App.vue'
import VueGtm from "vue-gtm";
require('@/assets/sass/main.sass')

import store from "@/store"

Vue.use(VueGtm, {
  id: "GTM-T6TT65V",
  defer: false,
  enabled: true,
  debug: false,
  loadScript: true,
  trackOnNextTick: false
});

// mixins
import globalFunctions from '@/mixins/globalFunctions.js'
Vue.mixin(globalFunctions)

import VueScreen from 'vue-screen'
Vue.use(VueScreen)

Vue.config.productionTip = false

new Vue({
  store,
  render: h => h(App),
}).$mount('#app')
