<template>
  <div class="choose-logo-color">
    <div class="gap" />
    <strong>Logo-Farbe</strong>
    <div class="gap" />
    <Colors
      :colors="colors"
      :active-color="currentLogoColor"
      @setColor="setColor"
    />
  </div>
</template>

<script>
import '@creativebulma/bulma-tooltip/dist/bulma-tooltip.min.css'
import { categoryLogoColorsId } from '@/variables.js'
import Colors from  '@/components/Colors'

export default {
  components: { Colors },
  computed: {
    currentLogoColor: {
      set (col) {
        this.$store.commit("setCurrentLogoColor", { name: col.name, hexCode: col.colors.hexCode, ralCode: col.colors.ralCode })
      },
      get () {
        return this.$store.state.currentLogoColor
      }
    },
    colors () {
      // category ID for Logo Colors: 291
      // sort by menuOrder as integer
      return this.$store.getters.getColorsForModel(categoryLogoColorsId).filter(col => col.colors.hexCode != '').sort((a, b) => parseInt(a.menuOrder) - parseInt(b.menuOrder))
    }
  },
  mounted () {
    if (this.colors.length > 0) {
      this.setColor(this.colors[0])
    }
  },
  methods: {
    setColor (item) {
      this.currentLogoColor = item
    },
  },
}
</script>

<style lang="sass" scoped>

</style>
