<template>
  <div class="choose-model" v-if="categoryModels.length > 0">
    <div class="gap" />
    <strong>Modell</strong>
    <div class="gap" />
    <div class="custom-select is-clickable" @click="componentDropdownExpanded = !componentDropdownExpanded">
      {{ currentModel.name }}
      <img src="@/assets/icons/chevron-down.svg" />
    </div>
    <div v-if="componentDropdownExpanded" class="custom-select expanded">
      <div v-for="(model, index) in categoryModels" :key="index" class="inner is-clickable">
        <div class="columns is-mobile is-gapless">
          <div class="column">
            <span
              @click="changeModel(model)"
              :class="['product-name', { 'is-active' : model === currentModel}]"
            >
              {{ model.name }}
            </span>
          </div>
          <div class="column is-narrow">
            <div v-if="model.shortDescription" class="more-info-link has-text-grey is-clickable is-size-7" @click="toggleInfoBox(model)"><u>Info</u></div>
          </div>
        </div>

        <ProductInfo
          :has-picture="false"
          :product="model"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ProductInfo from '@/components/ProductInfo'
export default {
  components: { ProductInfo },
  data () {
    return {
      selectedModel: null,
      componentDropdownExpanded: false
    }
  },
  computed: {
    categoryModels () {
      return this.$store.getters.getModelsByCategory
    },
    loading () {
      return this.$store.state.loading
    },
  },
  methods: {
    // TODO DRY!!
    toggleInfoBox (product) {
      // close all other info boxes
      this.categoryModels.forEach(prod => {
        prod.hasInfoBoxOpen = false
      })

      product.hasInfoBoxOpen = true
    },
    changeModel (model) {
      this.currentModel = model
      this.componentDropdownExpanded = false
      model.hasInfoBoxOpen = false
    }
  }
}
</script>

<style lang="css" scoped>
</style>
