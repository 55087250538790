<template>
  <div class="choose-frame-color">
    <div class="gap" />
    <strong>Rahmen-Farbe</strong>
    <div class="columns is-gapless">
      <div class="column is-6">
        <ChooseColorBase />
      </div> {{ defaultColors.hexCode }}
      <div class="column is-6">
        <div class="field">
          <label class="label is-small">RAL Code</label>
          <div class="control has-icons-right">
            <input class="input is-small" type="text" placeholder="1001" v-model="searchQuery" @keyup="setSearchQuery()">
            <span class="icon is-small is-right is-clickable" @click="searchQuery = ''">
              <i class="fas fa-times"></i>
            </span>
          </div>
        </div>
      </div>
    </div>

    <div>
      <Colors
        v-if="searchQuery.length > 0"
        :colors="searchedColors"
        :active-color="currentFrameColor"
        @setColor="setColor"
      />
      <div v-else>
        <Colors
          :colors="defaultColors"
          :active-color="currentFrameColor"
          @setColor="setColor"
        />

        <div class="show-colors">
          <u class="is-clickable has-text-grey" v-if="showMoreColors" @click="showMoreColors = false">Weitere Farben ausblenden</u>
          <u class="is-clickable has-text-grey" v-else @click="showMoreColors = true">Weitere Farben einblenden</u>
        </div>

        <Colors
          v-if="showMoreColors"
          :colors="moreColors"
          :active-color="currentFrameColor"
          @setColor="setColor"
        />
      </div>
    </div>
  </div>
</template>

<script>
import '@creativebulma/bulma-tooltip/dist/bulma-tooltip.min.css'
import { categoryFrameColorsId } from '@/variables.js'
import Colors from  '@/components/Colors'
import ChooseColorBase from '@/components/ChooseColorBase'

export default {
  components: { Colors, ChooseColorBase },
  data () {
    return {
      showMoreColors: false,
      searchQuery: ''
    }
  },
  computed: {
    currentFrameColor: {
      set (col) {
        this.$store.commit("setCurrentFrameColor", { name: col.name, hexCode: col.colors.hexCode, ralCode: col.colors.ralCode, customPrice: col.customPrice})
      },
      get () {
        return this.$store.state.currentFrameColor
      }
    },
    colors () {
      return this.$store.getters.getColorsForModel(categoryFrameColorsId).sort((x,y) => {return x.menuOrder - y.menuOrder})
    },
    defaultColors () {
      return this.$store.getters.getColorsForModel(categoryFrameColorsId).sort((x,y) => {return x.menuOrder - y.menuOrder}).filter(col => col.isDefault === true)
    },
    moreColors () {
      return this.$store.getters.getColorsForModel(categoryFrameColorsId).sort((x,y) => {return x.menuOrder - y.menuOrder}).filter(col => col.isDefault === false)
    },
    searchedColors () {
      let searched = this.colors
      // Search Query Filter
      let lowerCasedSearchQuery = this.searchQuery.toLowerCase()

      if (lowerCasedSearchQuery != '') {
        let text = lowerCasedSearchQuery.split(' ');

        searched = searched.filter(item => {
          return text.every(el => {
            if (item.colors.ralCode) {
              let lowerCasedName = item.colors.ralCode.toLowerCase()
              return lowerCasedName.indexOf(el) > -1
            }
          })
        })
      }

      return searched
    }
    
  },
  mounted () {
    if (this.defaultColors) {
      this.currentFrameColor = this.defaultColors[0]
    }
  },
  methods: {
    setColor (item) {
      this.currentFrameColor = item
    }
  },
  watch: {
    defaultColors () {
      let hasIt = this.currentFrameColor.hexCode
      if (this.defaultColors.includes(hasIt)) {
        this.setColor(this.defaultColors[0])
      }
    }
  }
}
</script>

<style lang="sass" scoped>
@import "~@/assets/sass/utilities/initial-variables.sass"
.show-colors
  margin-top: 0
  margin-bottom: $base-gap
</style>
