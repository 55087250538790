<template>
  <div class="choose-height">
    <div class="gap" />

    <strong>Rahmenhöhe</strong>

    <div @click="showInfoModal = true" class="is-pulled-right has-text-grey is-clickable"><u>Info zur Ermittlung</u></div>

    <info-modal-height
      v-if="showInfoModal"
      @close="showInfoModal = false"
    />

    <div class="control">
      <div class="radio-custom" v-if="currentModel.frameHeightsM.length > 0">
        <input type="radio" name="sex" v-model="currentGeometry" :value="{key: 'frameHeightsM', name: 'Männer'}" id="height-m">
        <label for="height-m">Männer</label>
      </div>
      <div class="radio-custom" v-if="currentModel.frameHeightsW.length > 0">
        <input type="radio" name="sex" v-model="currentGeometry" :value="{key: 'frameHeightsW', name: 'Frauen'}" id="height-w">
        <label for="height-w">Frauen</label>
      </div>
      <div class="radio-custom" v-if="currentModel.frameHeightsWave.length > 0">
        <input type="radio" name="sex" v-model="currentGeometry" :value="{key: 'frameHeightsWave', name: 'Tiefeinsteiger'}" id="height-wave">
        <label for="height-wave">Tiefeinsteiger</label>
      </div>
    </div>

    <div class="custom-select is-clickable" @click="componentDropdownExpanded = !componentDropdownExpanded">
      {{ currentHeight }} mm
      <img src="@/assets/icons/chevron-down.svg" />
    </div>
    <div v-if="componentDropdownExpanded" class="custom-select expanded">
      <div v-for="(height, index) in currentModel[currentGeometry.key]" :key="index" :class="['product-name inner is-clickable', { 'is-active' : height === currentHeight}]" @click="currentHeight = height; componentDropdownExpanded = false">
        {{ height }} mm
      </div>
    </div>
  </div>
</template>

<script>
import InfoModalHeight from '@/components/InfoModalHeight'
export default {
  components: { InfoModalHeight },
  data () {
    return {
      componentDropdownExpanded: false,
      showInfoModal: false
    }
  },
  computed: {
    categoryModels () {
      return this.$store.getters.getModelsByCategory
    },
    currentGeometry: {
      set (geo) {
        this.$store.commit('setCurrentGeometry', geo)
      },
      get () {
        return this.$store.state.currentGeometry
      }
    },
    currentHeight: {
      set (hei) {
        this.$store.commit('setCurrentHeight', hei)
      },
      get () {
        return this.$store.state.currentHeight
      }
    },
    isFrozen () {
      return this.$store.state.isFrozen
    },
  },
  mounted () {
    this.updateHeight()
  },
  methods: {
    updateHeight () {
      if (!this.isFrozen) {
        // set default Geometry to Women if no Men is available
        if (this.currentModel['frameHeightsM'] == 0) {
          this.currentGeometry = { key: 'frameHeightsW', name: 'Frauen' }
        } else {
          this.currentGeometry = { key: 'frameHeightsM', name: 'Männer' }
        }
        this.currentHeight = this.currentModel[this.currentGeometry.key][0]
      }
    }
  },
  watch: {
    currentModel () {
      this.updateHeight()
    },
  }
}
</script>

<style lang="sass" scoped>
</style>
