<template>
  <div v-if="currentModel">
    <choose-component-by-select
      label="Schalt- und Antriebsgruppe"
      :category-id="257"
      category="basics"
      category-type="gearshift"
      :multidimensional-dependencies="true"
    />

    <choose-component-by-select
      label="Antrieb"
      :category-id="264"
      category="basics"
      category-type="drive"
      variation-type="driveVariation"
      @updateKey="driveKey = Date.now()"
      :key="driveKey"
    />

    <choose-component-by-select
      label="Akku"
      :category-id="313"
      category="basics"
      category-type="battery"
    />

    <choose-component-by-select
      label="Display"
      :category-id="314"
      category="basics"
      category-type="display"
    />

    <choose-component-by-select
      label="Bremsen"
      :category-id="276"
      category="basics"
      category-type="brakes"
      :multidimensional-dependencies="true"
      @updateKey="brakesKey = Date.now()"
      :key="brakesKey"
    />

    <choose-component-by-select
      label="Steuersatz"
      :category-id="316"
      category="basics"
      category-type="headset"
    />

    <choose-component-by-select
      label="Gabel"
      :category-id="277"
      category="basics"
      category-type="fork"
    />
  </div>
</template>

<script>
import ChooseComponentBySelect from '@/components/ChooseComponentBySelect'
export default {
  components: { ChooseComponentBySelect },
  data () {
    return {
      driveKey: Date.now(),
      brakesKey: Date.now() + 1,
    }
  }
}
</script>

<style lang="css" scoped>
</style>
