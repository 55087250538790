<template>
  <div class="choose-component-select" v-if="products.length > 0">
    <div class="gap" />
    <strong>
      {{ label }}
    </strong>
    <div class="gap" />

    <div :class="['custom-select', { 'is-clickable' : products.length > 1 }]" @click="componentDropdownExpanded = !componentDropdownExpanded" v-if="selectType === 'select' && currentComponent">
      <div class="columns is-gapless is-mobile">
        <div class="column">
          {{ currentComponent.name }} {{ getAdditionalPrice(currentComponent, products) }}
           <span v-if="currentComponent.weight >= 0">+</span> {{ currentComponent.weight.toLocaleString('de-DE') }}g
        </div>
        <div class="column is-narrow">
          <img src="@/assets/icons/chevron-down.svg" v-if="products.length > 1" />
          <div v-else-if="products.length === 1">
            <div v-if="products[0].shortDescription" class="more-info-link has-text-grey is-clickable is-size-7" @click="toggleInfoBox(products[0])"><u>Info</u></div>
          </div>
        </div>
      </div>
    </div>
    <div class="single" v-if="products.length === 1">
      <ProductInfo
        :product="products[0]"
      />
    </div>
    <div v-if="componentDropdownExpanded && products.length > 1" class="custom-select expanded">
      <div v-for="(product, index) in products" :key="index" class="inner is-clickable">

        <div class="columns is-gapless is-mobile">
          <div class="column">
            <span
              @click="currentComponent = product; componentDropdownExpanded = false; product.hasInfoBoxOpen = false"
              :class="['product-name', { 'is-active' : product === currentComponent}]">
              {{ product.name }} {{ getAdditionalPrice(product, products) }}
                <span v-if="product.weight">
                  <strong class="" :class="(product.weight <= 0) ? 'has-number-positive' : 'is-negative'">
                    <span v-if="product.weight >= 0">+</span>
                    {{ product.weight.toLocaleString('de-DE') }}g
                  </strong>
                </span>
                <span v-else>
                  <strong>+ 0g</strong>
                </span>
            </span>
          </div>
          <div class="column is-narrow">
            <div v-if="product.shortDescription" class="more-info-link has-text-grey is-clickable is-size-7" @click="toggleInfoBox(product)"><u>Info</u></div>
          </div>
        </div>
        <ProductInfo
          :product="product"
        />
      </div>
    </div>
    <div class="control radio-group" v-if="selectType === 'radio' && currentComponent">
      <div v-for="(product, index) in products" :key="index">
        <div class="radio-custom">
          <div class="columns is-gapless is-mobile">
            <div class="column">
              <input type="radio" :name="categoryType" v-model="currentComponent" :value="product" :id="`${product.sku}`">
              <label :for="`${product.sku}`">{{ product.name }} {{ getAdditionalPrice(product, products) }}</label>
            </div>
            <div class="column is-narrow">
              <div v-if="product.shortDescription" class="more-info-link in-radio has-text-grey is-clickable is-size-7" @click="toggleInfoBox(product)"><u>Info</u></div>
            </div>
          </div>
        </div>
        <div class="single" v-if="product.shortDescription">
          <ProductInfo
            :product="product"
          />
        </div>
      </div>
    </div>
    <!-- Some variations have customPrices set. TODO: filter the product name correctly and also consider customPrice
    Example: Gates Carbon Drive CDX Zahnriemen (Pinion) -->
    <div v-if="currentComponent">
      <div v-if="currentComponent.variations.length > 0" class="has-relative-margin-left-base">
        <div class="gap" />
        <strong class="has-text-grey">
          Variante
        </strong>
        <div class="gap" />
        <div class="custom-select is-clickable" @click="variationDropdownExpanded = !variationDropdownExpanded" v-if="currentComponentVariation">
          <div class="columns is-gapless is-mobile">
            <div class="column">
              {{ currentComponentVariation.name }}
            </div>
            <div class="column is-narrow">
              <img src="@/assets/icons/chevron-down.svg" />
            </div>
          </div>
        </div>
        <div v-if="variationDropdownExpanded" class="custom-select expanded">
          <div v-for="(componentVariation, index) in currentComponent.variations" :key="index" @click="currentComponentVariation = componentVariation; variationDropdownExpanded = false" :class="['product-name inner is-clickable', { 'is-active' : componentVariation === currentComponentVariation}]">
            {{ componentVariation.name }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProductInfo from '@/components/ProductInfo'
export default {
  components: { ProductInfo },
  props: {
    label: {
      type: String,
      required: true
    },
    categoryId: {
      type: Number,
      required: true
    },
    category: {
      type: String,
      required: true
    },
    categoryType: {
      type: String,
      required: true
    },
    variationType: {
      type: String
    },
    selectType: {
      type: String,
      default: 'select'
    },
    multidimensionalDependencies: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      componentDropdownExpanded: false,
      variationDropdownExpanded: false
    }
  },
  computed: {
    isFrozen () {
      return this.$store.state.isFrozen
    },
    allProducts () {
      return this.$store.getters.getComponentForModelByCategoryId(this.categoryId)
    },
    products () {
      let allProducts = this.allProducts
      const productIdsToFilterOut = []

      allProducts.forEach(prod =>{
        if (this.filterIfDependencyAvailable(prod)) {
          productIdsToFilterOut.push(prod.id)
        }
      })

      return allProducts.filter(prod => !productIdsToFilterOut.includes(prod.id)).sort((x,y) => {return x.menuOrder - y.menuOrder})
    },
    currentComponents () {
      return this.$store.state.currentComponents
    },
    currentComponent: {
      set (c) {
        this.$store.commit('setComponent', { category: this.category, type: this.categoryType, value: c })
      },
      get () {
        return this.currentComponents[this.category][this.categoryType]
      }
    },
    currentComponentVariation: {
      set (drv) {
        this.$store.commit('setComponent', { category: this.category, type: this.variationType, value: drv })
      },
      get () {
        return this.currentComponents[this.category][this.variationType]
      }
    },
    currentConfiguration () {
      return this.$store.getters.getCurrentConfiguration
    },
  },
  mounted () {
    this.updateComponent()
  },
  destroyed () {
    // reset component when component destroyed
    this.currentComponent = null
    this.currentComponentVariation = null
  },
  methods: {
    updateComponent () {

      // do not update component if frozen
      if (this.isFrozen) {
        return
      }

      const baseComponent = this.getBaseForCurrentModel(this.products)
      if (baseComponent) {
        this.currentComponent = baseComponent
      } else {
        this.currentComponent = this.products[0]
      }
    },
    // if the component depends on other components we search the reduced component string for the SKU
    // if the SKU is available we show this component, if not, then not
    filterIfDependencyAvailable (component) {
      let returnValue = false
      const str = JSON.stringify(this.currentReducedComponents)

      if (component.dependsOn) {
        // consider multidimensional dependencies if relevant
        if (this.multidimensionalDependencies) {
            // check the categories of dependencies
            let dependencyCategories = []
            let cat = ''
            component.dependsOn.forEach(item => {
              cat = item.substring(0, 2)
              if (!dependencyCategories.includes(cat)) {
                dependencyCategories.push(cat)
              }
            })

            // split dependsOn into array of arrays as component dependencies from different categories have to be combined
            let dependencies = []
            dependencyCategories.forEach(cat => {
              let subDependencies = []
              component.dependsOn.forEach(dep => {
                if (dep.substring(0, 2) === cat) {
                  subDependencies.push(dep)
                }
              })
              dependencies.push(subDependencies)
            })

            let resultArray = []
            dependencies.forEach(dep => {
              resultArray.push(dep.find(v => str.includes(v)) === undefined)
            })

            if (resultArray.length > 1 && resultArray.includes(true)) {
              returnValue = true
            } else if (resultArray.length === 1) {
              returnValue = component.dependsOn.find(v => str.includes(v)) === undefined
            } else {
              returnValue = false
            }

            // if (component.sku === "1450") {
            //   console.log("LOG 1450:")
            //   console.log(dependencyCategories)
            //   console.log(returnValue)
            //   console.log(component.dependsOn.find(v => str.includes(v)) === undefined)
            // }
        } else {
          returnValue = component.dependsOn.find(v => str.includes(v)) === undefined
        }


        return returnValue
      } else {
        return false
      }
    },
    toggleInfoBox (product) {
      // close all other info boxes
      this.allProducts.forEach(prod => {
        prod.hasInfoBoxOpen = false
      })

      product.hasInfoBoxOpen = true
    }
  },
  watch: {
    currentModel () {
      this.updateComponent()
    },
    currentComponent () {
      // if this component has variations, choose the first one when currentComponent is swapped
      this.currentComponentVariation = null
      if (this.currentComponent && this.currentComponent.variations.length > 0) {
        this.currentComponentVariation = this.currentComponent.variations[0]
      }
    },
    products () {
      // if there are no products we need to remove this component
      if (this.products.length === 0) {
        this.currentComponent = null
        this.currentComponentVariation = null
      } else {

        if (!this.currentComponent) {
          // re-mount the component if no component is set so we can set again the Base
          this.$emit('updateKey')
        } else {
          // check if current component is in products. if not, set the first product
          let isInProducts = this.products.filter(prod => prod.sku === this.currentComponent.sku).length > 0
          if (!isInProducts) {
            this.currentComponent = this.products[0]
          }
        }
      }
    }
  }
}
</script>

<style lang="sass" scoped>
@import "~@/assets/sass/utilities/initial-variables.sass"
.radio-group
  margin-top: -$base-gap
.more-info-link.in-radio
  margin-top: 13px

.is-negative
  color: $red!important

.has-number-positive
  color: #248C46
</style>
