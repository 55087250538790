export default {
  computed: {
    currentModel: {
      set (mod) {
        this.$store.commit('setCurrentModel', mod)
      },
      get () {
        return this.$store.state.currentModel
      }
    },
    categoryModels () {
      return this.$store.getters.getModelsByCategory
    },
    currentCategory: {
      set (cat) {
        this.$store.commit('setCurrentCategory', cat)
        this.$store.commit('setCurrentModel', this.categoryModels[0])
      },
      get () {
        return this.$store.state.currentCategory
      }
    },
    currentReducedComponents () {
      return this.$store.state.currentReducedComponents
    }
  },
  methods: {
    getAdditionalPrice (product, products) {
      // check the base price for this component
      let basePrice = 0
      let totalPrice = 0

      if (this.getBaseForCurrentModel(products)) {
        basePrice = this.getBaseForCurrentModel(products).customPrice
      }

      totalPrice = product.customPrice - basePrice

      if (product.baseOf.includes(this.currentModel.sku)) {
        return "(Standard)"
      } else if (totalPrice != 0){
        // substract the base price from the custom price as this is already included
        // its also possible to have negative prices then

        if (totalPrice < 0) {
          return `${totalPrice} EUR`
        } else {
          return `+ ${totalPrice} EUR`
        }
      }
    },
    getBaseForCurrentModel (products) {
      return products.filter(prod => prod.baseOf.includes(`${this.currentModel.sku}`))[0]
    },
    // helper function to specially remove a component if an SKU is not available
    exceptionIncluded (sku) {
      if (sku) {
        const str = JSON.stringify(this.currentReducedComponents)

        return [sku].find(v => str.includes(v)) === undefined
      } else {
        return false
      }
    },
    scrollToTop () {
      window.scrollTo(0,0)
    },
    getColorByBgColor (bgColor) {
      return (parseInt(bgColor.replace('#', ''), 16) > 0xffffff / 2) ? '#000' : '#fff';
    },
    formatPrice (price) {
      return price.toLocaleString('de-DE', { minimumFractionDigits: 2, style: 'currency', currency: 'EUR' })
    }
  }
}
