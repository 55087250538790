<template>
  <div class="modal is-active">
    <div class="modal-background" @click="emitClose()"></div>
    <div class="modal-content">
      <header class="modal-card-head">
        <p class="modal-card-title" v-if="editMode">Individuelles Modell speichern</p>
        <p class="modal-card-title" v-else>Mein individuelles Modell anfragen</p>
        <button class="delete" aria-label="close" @click="emitClose()"></button>
      </header>
      <bike-preview
        v-if="currentModel && currentCategory"
      />
      <section class="modal-card-body footerless has-text-dark">

        <p>Senden Sie ihr konfiguriertes Modell an uns und wir melden uns umgehend um mit Ihnen die Realisierung ihres Wunschrades zu besprechen.</p>

        <div class="field">
          <div class="gap" />
          <label class="label">Bitte wählen Sie eine Option:</label>
          <div class="gap" />
          <div>
            <label class="radio">
              <input selected type="radio" name="requestType" v-model="request.type" value="Ich bin mir noch unsicher und nehme bei Bedarf selbst wieder Kontakt zu Ihnen auf.">
              Ich bin mir noch unsicher und nehme bei Bedarf selbst wieder Kontakt zu Ihnen auf.
            </label>
            </div>
          <div>
            <label class="radio">
              <input type="radio" name="requestType" v-model="request.type" value="Ich benötige noch Hilfe bzw. Beratung zu den in Anmerkungen notierten Themen.">
              Ich benötige noch Hilfe bzw. Beratung zu den in Anmerkungen notierten Themen.
            </label>
          </div>
          <div>
            <label class="radio">
              <input type="radio" name="requestType" v-model="request.type" value="Ich möchte das konfigurierte Rad gern bestellen. Bitte überprüfen sie meine Konfiguration und persönlichen Angaben und senden Sie mir eine Auftragsbestätigung.">
              Ich möchte das konfigurierte Rad gern bestellen. Bitte überprüfen sie meine Konfiguration und persönlichen Angaben und senden Sie mir eine Auftragsbestätigung.
            </label>
          </div>
          <div>
            <label class="radio">
              <input type="radio" name="requestType" v-model="request.type" value="Ich möchte das Rad persönlich bei Velofaktum vor Ort bestellen und melde mich bei Gelegenheit wegen eines Termins.">
              Ich möchte das Rad persönlich bei Velofaktum vor Ort bestellen und melde mich bei Gelegenheit wegen eines Termins.
            </label>
          </div>
        </div>

        <div class="field">
          <label class="label" for="modelName">Modell Name <span class="has-text-danger">*</span></label>
          <input type="text" class="input" id="modelName" v-model="request.name" />
          <p>Bitte wählen Sie möglichst einen individuellen Modellnamen (z.B. Ihre Initialen und eine Ziffernfolge) um Irrtümer zu vermeiden.</p>
        </div>

        <p>Wir benötigen mindestens ihre E-Mailadresse um mit Ihnen Kontakt aufnehmen zu können. Gern können Sie uns untenstehend auch weitere Angaben übermitteln.</p>
        <div class="gap" />
        <div class="columns is-gapless">
          <div class="column is-6">

            <div class="field">
              <label class="label" for="formEmail">E-Mail <span class="has-text-danger">*</span></label>
              <input type="email" class="input" id="formEmail" v-model="request.email" />
            </div>

            <div class="field">
              <label class="label" for="formFullName">Name</label>
              <input type="text" class="input" id="formFullName" v-model="request.fullName" />
            </div>

            <div class="field">
              <label class="label" for="formCity">Ort</label>
              <input type="text" class="input" id="formCity" v-model="request.city" />
            </div>

            <div class="field">
              <label class="label" for="formPhone">Telefon</label>
              <input type="text" class="input" id="formPhone" v-model="request.phone" />
            </div>

          </div>
        </div>
        <div class="field">
          <label class="label" for="formComments">Anmerkungen</label>
          <textarea class="textarea" id="formComments" rows="6" v-model="request.comments"/>
        </div>

        <p>Ich möchte dass Sie eine Überprüfung der Rahmengröße vornehmen und die richtige Sitzposition bestimmen und übermittle Ihnen dafür noch folgende Maße:</p>
        <div class="gap" />

        <div class="columns is-gapless">
          <div class="column is-6">
            <div class="field has-addons">
              <p class="control">
                <input class="input" type="text" placeholder="Körpergröße" v-model="request.bodySize">
              </p>
              <p class="control">
                <a class="button is-static">
                  cm
                </a>
              </p>
            </div>
            <p class="is-size-7">(senkrecht an einer Wand stehend, ohne Schuhe, Füße ca. schulterbreit auseinander)</p>
            <div class="divider" />

            <div class="field has-addons">
              <p class="control">
                <input class="input" type="text" placeholder="Innenbeinlänge" v-model="request.insideLegLength">
              </p>
              <p class="control">
                <a class="button is-static">
                  cm
                </a>
              </p>
            </div>
            <p class="is-size-7">(Haltung wie oben, drücken Sie eine Wasserwaage o.ä. in den Schritt, der Druck sollte dem Druck entsprechen, den Sie beim Sitzen auf dem Sattel empfinden. Messen Sie den Abstand vom Boden zur Wasserwaage- Oberkante.)</p>
            <div class="divider" />

            <div class="field has-addons">
              <p class="control">
                <input class="input" type="text" placeholder="Armspannweite" v-model="request.armSpan">
              </p>
              <p class="control">
                <a class="button is-static">
                  cm
                </a>
              </p>
            </div>
            <p class="is-size-7">(Stellen Sie sich aufrecht stehend an eine freie Wand und strecken Sie die Arme waagerecht auseinander. Die Armspannweite entspricht der Entfernung der Fingerspitzen beider Mittelfinger.)</p>
            <div class="divider" />

            <div class="field has-addons">
              <p class="control">
                <input class="input" type="text" placeholder="Schulterbreite" v-model="request.shoulderWidth">
              </p>
              <p class="control">
                <a class="button is-static">
                  cm
                </a>
              </p>
            </div>
            <p class="is-size-7">(Abstand der beiden oberen, äußeren Enden der Oberarmknochen, die Arme dabei locker hängen lassen)</p>
            <div class="divider" />

            <div class="field has-addons">
              <p class="control">
                <input class="input" type="text" placeholder="Körpergewicht" v-model="request.bodyWeight">
              </p>
              <p class="control">
                <a class="button is-static">
                  kg
                </a>
              </p>
            </div>

            <div class="field has-addons">
              <p class="control">
                <input class="input" type="text" placeholder="Schuhgröße" v-model="request.shoeSize">
              </p>
              <p class="control">
                <a class="button is-static">
                  EU-Maß
                </a>
              </p>
            </div>

            <div class="field has-addons">
              <p class="control">
                <input class="input" type="text" placeholder="Kilometerleistung" v-model="request.km">
              </p>
              <p class="control">
                <a class="button is-static">
                  km
                </a>
              </p>
            </div>
            <p class="is-size-7">Jahreskilometerleistung auf dem Rad (circa)</p>
            <div class="divider" />
          </div>
        </div>
        <div class="field">
          <label class="checkbox">
            <input type="checkbox" v-model="request.termsAccepted">
            Mit dem Absenden des Kontaktformulars erklären Sie sich damit einverstanden, dass Ihre Daten zur Bearbeitung Ihres Anliegens verwendet werden (Weitere Informationen und Widerrufshinweise finden Sie in der <a href="https://www.velofaktum.de/datenschutzerklaerung" target="_blank">Datenschutzerklärung</a>) <span class="has-text-danger">*</span>
          </label>
        </div>

        <button :disabled="formIncomplete" :class="['button is-success', { 'is-loading' : loading }]" @click="sendRequest()">
          <span v-if="editMode">Modell speichern</span>
          <span v-else>Modell bei Velofaktum anfragen</span>
        </button>

        <div class="has-text-danger" v-if="error">Es ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.</div>

        <div class="is-size-5 has-text-green" v-if="successfullySubmitted && !editMode">Vielen Dank für Ihre Anfrage. Wir haben Ihnen eine Bestätigung an die angegebene E-Mail Adresse geschickt und Sie hören von uns in Kürze.</div>

        <div class="is-size-5 has-text-green" v-if="successfullySubmitted && editMode">Vielen Dank. Wir haben Ihr Modell aktualisiert und übermittelt.</div>

        <div class="has-text-danger" v-if="formIncomplete">
          <div v-if="request.name.length === 0">Bitte geben Sie Ihrem Modell einen Namen</div>
          <div v-if="request.email.length === 0">Bitte geben Sie eine E-Mail Adresse an</div>
          <div v-if="!request.termsAccepted">Bitte akzeptieren Sie unsere Datenschutzerklärung</div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import client from '@/client'
import BikePreview from '@/components/BikePreview'
export default {
  components: { BikePreview },
  props: {
    editMode: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      request: {
        type: "Ich bin mir noch unsicher und nehme bei Bedarf selbst wieder Kontakt zu Ihnen auf.",
        name: '',
        email: '',
        fullName: null,
        city: null,
        phone: null,
        comments: null,
        bodySize: null,
        insideLegLength: null,
        armSpan: null,
        shoulderWidth: null,
        bodyWeight: null,
        shoeSize: null,
        km: null,
        termsAccepted: false,
      },
      loading: false,
      successfullySubmitted: false,
      error: false
    }
  },
  computed: {
    configuration () {
      return this.$store.getters.getCurrentConfiguration
    },
    formIncomplete () {
      return this.request.name.length === 0 || this.request.email.length === 0 || !this.request.termsAccepted
    },
    formData () {
      return this.$store.state.formData
    },
    requestId () {
      return this.$store.state.requestId
    },
    totalPrice () {
      return this.$store.getters.getTotalPrice
    },
    totalWeight () {
      return this.$store.getters.getTotalWeight
    },
  },
  mounted () {
    if (this.editMode) {
      this.request = this.formData
    }
  },
  methods: {
    emitClose () {
      this.$emit('close')
    },
    async sendRequest () {
      this.loading = true
      let sendData = { name: this.request.name, form_data: this.request, configuration: this.configuration, total_price: this.totalPrice, total_weight: this.totalWeight }
      try {
        let data = undefined
        if (this.editMode) {
          data = await client.put(`/order_requests/${this.requestId}`, sendData)
        } else {
          data = await client.post('/order_requests', sendData)
        }

        if (data.status === 201 || data.status === 200) {
          this.successfullySubmitted = true
        }
        this.error = false
        this.loading = false
      } catch (error) {
        console.log("Error: " + error)
        this.error = true
        this.loading = false
      }
    },
  },
}
</script>

<style lang="sass" scoped>
</style>
