<template>
  <div v-if="currentModel">
    <choose-component-by-select
      label="Laufradsatz"
      :category-id="278"
      category="options"
      category-type="wheelset"
    />

    <choose-component-by-select
      label="Felgen"
      :category-id="279"
      category="options"
      category-type="rims"
      v-if="!exceptionIncluded('2150')"
      @updateKey="rimsKey = Date.now()"
      :key="rimsKey"
    />

    <choose-component-by-select
      label="Speichen"
      :category-id="280"
      category="options"
      category-type="spokes"
      v-if="!exceptionIncluded('2150')"
    />

    <choose-component-by-select
      label="Naben"
      :category-id="281"
      category="options"
      category-type="hubs"
      v-if="!exceptionIncluded('2150')"
      :multidimensional-dependencies="true"
    />

    <choose-component-by-select
      label="Reifen"
      :category-id="282"
      category="options"
      category-type="wheels"
      variation-type="wheelsVariation"
    />

    <choose-component-by-select
      label="Vorbau"
      :category-id="283"
      category="options"
      category-type="stem"
      variation-type="stemVariation"
    />

    <choose-component-by-select
      label="Lenker-Art"
      :category-id="315"
      category="options"
      category-type="handlebarType"
      select-type="radio"
      @updateKey="handlebarTypeKey = Date.now()"
      :key="handlebarTypeKey"
    />

    <choose-component-by-select
      label="Lenker"
      :category-id="284"
      category="options"
      category-type="handlebars"
      variation-type="handlebarsVariation"
    />

    <choose-component-by-select
      label="Griffe"
      :category-id="286"
      category="options"
      category-type="grips"
      variation-type="gripsVariation"
      @updateKey="gripsKey = Date.now()"
      :key="gripsKey"
    />

    <choose-component-by-select
      label="Lenkerband"
      :category-id="287"
      category="options"
      category-type="handlebarTape"
      variation-type="handlebarTapeVariation"
      @updateKey="handlebarTapeKey = Date.now()"
      :key="handlebarTapeKey"
    />

    <choose-component-by-select
      label="Pedale"
      :category-id="288"
      category="options"
      category-type="pedals"
      variation-type="pedalsVariation"
    />

    <choose-component-by-select
      label="Sattel"
      :category-id="289"
      category="options"
      category-type="saddle"
      variation-type="saddleVariation"
    />

    <choose-component-by-select
      label="Sattelstütze"
      :category-id="290"
      category="options"
      category-type="seatPost"
      variation-type="seatPostVariation"
    />
  </div>
</template>

<script>
import ChooseComponentBySelect from '@/components/ChooseComponentBySelect'
export default {
  components: { ChooseComponentBySelect },
  data () {
    return {
      handlebarTapeKey: Date.now(),
      gripsKey: Date.now() + 1,
      handlebarTypeKey: Date.now() + 2,
      rimsKey: Date.now() + 3
    }
  }
}
</script>

<style lang="css" scoped>
</style>
