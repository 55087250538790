<template>
  <div>
    <choose-category />

    <choose-model
      v-if="currentModel"
    />

    <choose-height
      v-if="currentModel"
    />

    <choose-frame-color
      v-if="currentModel"
    />

    <choose-logo-color
      v-if="currentModel"
    />
  </div>
</template>

<script>
import ChooseCategory from '@/components/ChooseCategory'
import ChooseModel from '@/components/ChooseModel'
import ChooseFrameColor from '@/components/ChooseFrameColor'
import ChooseLogoColor from '@/components/ChooseLogoColor'
import ChooseHeight from '@/components/ChooseHeight'

export default {
  components: { ChooseCategory, ChooseModel, ChooseFrameColor, ChooseLogoColor, ChooseHeight },
}
</script>

<style lang="css" scoped>
</style>
