<template>
  <div>
    <div class="summary-container" id="summary">
      <div class="is-size-2 has-text-weight-bold is-size-3-mobile">Zusammenfassung</div>
      <div class="line" />
      <div class="columns summary-top">
        <div class="column is-4">
          <div v-if="configuration.category"><strong>Grundtyp:</strong> {{ configuration.category.name }}</div>
          <div v-if="configuration.model">
            <strong>Modell:</strong> {{ configuration.model.name }}
            <div class="is-pulled-right">{{ formatPrice(parseFloat(configuration.model.regularPrice)) }}</div>
          </div>
          <div v-if="configuration.geometry"><strong>Geometrie:</strong> {{ configuration.geometry.name }}</div>
          <div v-if="configuration.height"><strong>Rahmenhöhe:</strong> {{ configuration.height }} mm</div>
        </div>
        <div class="column is-4">
          <div v-if="totalWeight && configuration.model"><strong>Basisgewicht:</strong> {{ configuration.model.weight.toLocaleString('de-DE') }} g</div>
          <div v-if="configuration.colorBase"><strong>Oberfläche:</strong> {{ configuration.colorBase.name }}</div>
          <div v-if="configuration.frameColor && configuration.frameColor.name">
            <strong>Rahmen-Farbe:</strong> {{ configuration.frameColor.name }} {{ configuration.frameColor.ralCode }}
            <div class="is-pulled-right">{{ formatPrice(configuration.frameColor.customPrice) }}</div>
          </div>
          <div v-if="configuration.frameColor && configuration.frameColor.name"><strong>Logo-Farbe:</strong> {{ configuration.logoColor.name }} {{ configuration.logoColor.ralCode }}</div>
        </div>
        <div class="column is-4">
          <div v-if="configuration.model && configuration.model.weight">
            <strong>Verfügbarkeit:</strong>
              <div v-if="currentModel && currentModel.deliveryTime" class="is-pulled-right">{{ currentModel.deliveryTime }}</div>
              <div v-else class="is-pulled-right">auf Anfrage</div>
          </div>
          <div v-if="configuration.model && configuration.model.weight">
            <strong>Gesamtgewicht:</strong>
              <div class="is-pulled-right">{{ totalWeight.toLocaleString('de-DE') }} g</div>
          </div>
        </div>
      </div>
      <div class="line" />
      <div class="columns">
        <div class="column is-4" v-if="configuration.components.basics">
          <strong class="has-text-primary is-size-6">Basics</strong>
          <div v-if="configuration.components.basics.gearshift">
            <div class="gap" />
            <strong>Schalt- und Antriebsgruppe</strong>
            <div>
              {{ configuration.components.basics.gearshift.name }}
              <div class="is-pulled-right">{{ formatPrice(configuration.components.basics.gearshift.customPrice) }}</div>
               <div v-if="configuration.components.basics.gearshift.weight">
                  <strong class="is-pulled-right" :class="(configuration.components.basics.gearshift.weight <= 0) ? 'has-text-primary' : 'is-negative'">
                    <span v-if="configuration.components.basics.gearshift.weight >= 0">+</span>
                    {{ configuration.components.basics.gearshift.weight.toLocaleString('de-DE') }}g
                  </strong>
                </div>
              <div v-else>
                <strong class="is-size-8 is-pulled-right">0g</strong>
              </div>
              <br>
            </div>
          </div>
          <div v-if="configuration.components.basics.drive">
            <div class="gap" />
            <strong>Antrieb</strong>
            <div>
              {{ configuration.components.basics.drive.name }}
              <div class="is-pulled-right">{{ formatPrice(configuration.components.basics.drive.customPrice) }}</div>
               <div v-if="configuration.components.basics.drive.weight">
                  <strong class="is-pulled-right" :class="(configuration.components.basics.drive.weight <= 0) ? 'has-text-primary' : 'is-negative'">
                    <span v-if="configuration.components.basics.drive.weight >= 0">+</span>
                    {{ configuration.components.basics.drive.weight.toLocaleString('de-DE') }}g
                  </strong>
                </div>
              <div v-else>
                <strong class="is-size-8 is-pulled-right">0g</strong>
              </div>
              <div class="is-size-7" v-if="configuration.components.basics.driveVariation">{{ configuration.components.basics.driveVariation.name }}</div>
            </div>
            <br>
          </div>
          <div v-if="configuration.components.basics.battery">
            <div class="gap" />
            <strong>Akku</strong>
            <div>
              {{ configuration.components.basics.battery.name }}
              <div class="is-pulled-right">{{ formatPrice(configuration.components.basics.battery.customPrice) }}</div>
                <div v-if="configuration.components.basics.battery.weight">
                  <strong class="is-pulled-right" :class="(configuration.components.basics.battery.weight <= 0) ? 'has-text-primary' : 'is-negative'">
                    <span v-if="configuration.components.basics.battery.weight >= 0">+</span>
                    {{ configuration.components.basics.battery.weight.toLocaleString('de-DE') }}g
                  </strong>
                </div>
                <div v-else>
                  <strong class="is-size-8 is-pulled-right">0g</strong>
                </div>
                <br>
            </div>
          </div>

          <div v-if="configuration.components.basics.display">
            <div class="gap" />
            <strong>Display</strong>
            <div>
              {{ configuration.components.basics.display.name }}
              <div class="is-pulled-right">{{ formatPrice(configuration.components.basics.display.customPrice) }}</div>
               <div v-if="configuration.components.basics.display.weight">
                  <strong class="is-pulled-right" :class="(configuration.components.basics.display.weight <= 0) ? 'has-text-primary' : 'is-negative'">
                    <span v-if="configuration.components.basics.display.weight >= 0">+</span>
                    {{ configuration.components.basics.display.weight.toLocaleString('de-DE') }}g
                  </strong>
                </div>
                <div v-else>
                  <strong class="is-size-8 is-pulled-right">0g</strong>
                </div>
                <br>
            </div>
          </div>

          <div v-if="configuration.components.basics.brakes">
            <div class="gap" />
            <strong>Bremsen</strong>
            <div>
              {{ configuration.components.basics.brakes.name }}
              <div class="is-pulled-right">{{ formatPrice(configuration.components.basics.brakes.customPrice) }}</div>
               <div v-if="configuration.components.basics.brakes.weight">
                  <strong class="is-pulled-right" :class="(configuration.components.basics.brakes.weight <= 0) ? 'has-text-primary' : 'is-negative'">
                    <span v-if="configuration.components.basics.brakes.weight >= 0">+</span>
                    {{ configuration.components.basics.brakes.weight.toLocaleString('de-DE') }}g
                  </strong>
                </div>
                <div v-else>
                  <strong class="is-size-8 is-pulled-right">0g</strong>
                </div>
                <br>
            </div>
          </div>

          <div v-if="configuration.components.basics.headset">
            <div class="gap" />
            <strong>Steuersatz</strong>
            <div>
              {{ configuration.components.basics.headset.name }}
              <div class="is-pulled-right">{{ formatPrice(configuration.components.basics.headset.customPrice) }}</div>
                <div v-if="configuration.components.basics.headset.weight">
                  <strong class="is-pulled-right" :class="(configuration.components.basics.headset.weight <= 0) ? 'has-text-primary' : 'is-negative'">
                    <span v-if="configuration.components.basics.headset.weight >= 0">+</span>
                    {{ configuration.components.basics.headset.weight.toLocaleString('de-DE') }}g
                  </strong>
                </div>
                <div v-else>
                  <strong class="is-size-8 is-pulled-right">0g</strong>
                </div>
                <br>
            </div>
          </div>

          <div v-if="configuration.components.basics.fork">
            <div class="gap" />
            <strong>Gabel</strong>
            <div>
              {{ configuration.components.basics.fork.name }}
              <div class="is-pulled-right">{{ formatPrice(configuration.components.basics.fork.customPrice) }}</div>
                <div v-if="configuration.components.basics.fork.weight">
                  <strong class="is-pulled-right" :class="(configuration.components.basics.fork.weight <= 0) ? 'has-text-primary' : 'is-negative'">
                    <span v-if="configuration.components.basics.fork.weight >= 0">+</span>
                    {{ configuration.components.basics.fork.weight.toLocaleString('de-DE') }}g
                  </strong>
                </div>
                <div v-else>
                  <strong class="is-size-8 is-pulled-right">0g</strong>
                </div>
                <br>
            </div>
          </div>
        </div>

        <div class="column is-4" v-if="configuration.components.options">
          <strong class="has-text-primary is-size-6">Optionen</strong>

          <div v-if="configuration.components.options.wheelset">
            <div class="gap" />
            <strong>Laufradsatz</strong>
            <div>
              {{ configuration.components.options.wheelset.name }}
              <div class="is-pulled-right">{{ formatPrice(configuration.components.options.wheelset.customPrice) }}</div>
                <div v-if="configuration.components.options.wheelset.weight">
                  <strong class="is-pulled-right" :class="(configuration.components.options.wheelset.weight <= 0) ? 'has-text-primary' : 'is-negative'">
                    <span v-if="configuration.components.options.wheelset.weight >= 0">+</span>
                    {{ configuration.components.options.wheelset.weight.toLocaleString('de-DE') }}g
                  </strong>
                </div>
                <div v-else>
                  <strong class="is-size-8 is-pulled-right">0g</strong>
                </div>
                <br>
            </div>
          </div>

          <div v-if="configuration.components.options.rims">
            <div class="gap" />
            <strong>Felgen</strong>
            <div>
              {{ configuration.components.options.rims.name }}
              <div class="is-pulled-right">{{ formatPrice(configuration.components.options.rims.customPrice) }}</div>
                <div v-if="configuration.components.options.rims.weight">
                  <strong class="is-pulled-right" :class="(configuration.components.options.rims.weight <= 0) ? 'has-text-primary' : 'is-negative'">
                    <span v-if="configuration.components.options.rims.weight >= 0">+</span>
                    {{ configuration.components.options.rims.weight.toLocaleString('de-DE') }}g
                  </strong>
                </div>
                <div v-else>
                  <strong class="is-size-8 is-pulled-right">0g</strong>
                </div>
                <br>
            </div>
          </div>

          <div v-if="configuration.components.options.spokes">
            <div class="gap" />
            <strong>Speichen</strong>
            <div>
              {{ configuration.components.options.spokes.name }}
              <div class="is-pulled-right">{{ formatPrice(configuration.components.options.spokes.customPrice) }}</div>
                <div v-if="configuration.components.options.spokes.weight">
                  <strong class="is-pulled-right" :class="(configuration.components.options.spokes.weight <= 0) ? 'has-text-primary' : 'is-negative'">
                    <span v-if="configuration.components.options.spokes.weight >= 0">+</span>
                    {{ configuration.components.options.spokes.weight.toLocaleString('de-DE') }}g
                  </strong>
                </div>
                <div v-else>
                  <strong class="is-size-8 is-pulled-right">0g</strong>
                </div>
                <br>
            </div>
          </div>

          <div v-if="configuration.components.options.hubs">
            <div class="gap" />
            <strong>Naben</strong>
            <div>
              {{ configuration.components.options.hubs.name }}
              <div class="is-pulled-right">{{ formatPrice(configuration.components.options.hubs.customPrice) }}</div>
                <div v-if="configuration.components.options.hubs.weight">
                  <strong class="is-pulled-right" :class="(configuration.components.options.hubs.weight <= 0) ? 'has-text-primary' : 'is-negative'">
                    <span v-if="configuration.components.options.hubs.weight >= 0">+</span>
                    {{ configuration.components.options.hubs.weight.toLocaleString('de-DE') }}g
                  </strong>
                </div>
                <div v-else>
                  <strong class="is-size-8 is-pulled-right">0g</strong>
                </div>
                <br>
            </div>
          </div>

          <div v-if="configuration.components.options.wheels">
            <div class="gap" />
            <strong>Reifen</strong>
            <div>
              {{ configuration.components.options.wheels.name }}
              <div class="is-pulled-right">{{ formatPrice(configuration.components.options.wheels.customPrice) }}</div>
                <div v-if="configuration.components.options.wheels.weight">
                  <strong class="is-pulled-right" :class="(configuration.components.options.wheels.weight <= 0) ? 'has-text-primary' : 'is-negative'">
                    <span v-if="configuration.components.options.wheels.weight >= 0">+</span>
                    {{ configuration.components.options.wheels.weight.toLocaleString('de-DE') }}g
                  </strong>
                </div>
                <div v-else>
                  <strong class="is-size-8 is-pulled-right">0g</strong>
                </div>
                <br>
              <div class="is-size-7" v-if="configuration.components.options.wheelsVariation">{{ configuration.components.options.wheelsVariation.name }}</div>
            </div>
          </div>

          <div v-if="configuration.components.options.stem">
            <div class="gap" />
            <strong>Vorbau</strong>
            <div>
              {{ configuration.components.options.stem.name }}
              <div class="is-pulled-right">{{ formatPrice(configuration.components.options.stem.customPrice) }}</div>
                <div v-if="configuration.components.options.stem.weight">
                  <strong class="is-pulled-right" :class="(configuration.components.options.stem.weight <= 0) ? 'has-text-primary' : 'is-negative'">
                    <span v-if="configuration.components.options.stem.weight >= 0">+</span>
                    {{ configuration.components.options.stem.weight.toLocaleString('de-DE') }}g
                  </strong>
                </div>
                <div v-else>
                  <strong class="is-size-8 is-pulled-right">0g</strong>
                </div>
                <br>
              <div class="is-size-7" v-if="configuration.components.options.stemVariation">{{ configuration.components.options.stemVariation.name }}</div>
            </div>
          </div>

          <div v-if="configuration.components.options.handlebarType">
            <div class="gap" />
            <strong>Lenker-Art</strong>
            <div>
              {{ configuration.components.options.handlebarType.name }}
              <div class="is-pulled-right">{{ formatPrice(configuration.components.options.handlebarType.customPrice) }}</div>
                <div v-if="configuration.components.options.handlebarType.weight">
                  <strong class="is-pulled-right" :class="(configuration.components.options.handlebarType.weight <= 0) ? 'has-text-primary' : 'is-negative'">
                    <span v-if="configuration.components.options.handlebarType.weight >= 0">+</span>
                    {{ configuration.components.options.handlebarType.weight.toLocaleString('de-DE') }}g
                  </strong>
                </div>
                <div v-else>
                  <strong class="is-size-8 is-pulled-right">0g</strong>
                </div>
                <br>
            </div>
          </div>

          <div v-if="configuration.components.options.handlebars">
            <div class="gap" />
            <strong>Lenker</strong>
            <div>
              {{ configuration.components.options.handlebars.name }}
              <div class="is-pulled-right">{{ formatPrice(configuration.components.options.handlebars.customPrice) }}</div>
                <div v-if="configuration.components.options.handlebars.weight">
                  <strong class="is-pulled-right" :class="(configuration.components.options.handlebars.weight <= 0) ? 'has-text-primary' : 'is-negative'">
                    <span v-if="configuration.components.options.handlebars.weight >= 0">+</span>
                    {{ configuration.components.options.handlebars.weight.toLocaleString('de-DE') }}g
                  </strong>
                </div>
                <div v-else>
                  <strong class="is-size-8 is-pulled-right">0g</strong>
                </div>
                <br>
              <div class="is-size-7" v-if="configuration.components.options.handlebarsVariation">{{ configuration.components.options.handlebarsVariation.name }}</div>
            </div>
          </div>

          <div v-if="configuration.components.options.grips">
            <div class="gap" />
            <strong>Griffe</strong>
            <div>
              {{ configuration.components.options.grips.name }}
              <div class="is-pulled-right">{{ formatPrice(configuration.components.options.grips.customPrice) }}</div>
                <div v-if="configuration.components.options.grips.weight">
                  <strong class="is-pulled-right" :class="(configuration.components.options.grips.weight <= 0) ? 'has-text-primary' : 'is-negative'">
                    <span v-if="configuration.components.options.grips.weight >= 0">+</span>
                    {{ configuration.components.options.grips.weight.toLocaleString('de-DE') }}g
                  </strong>
                </div>
                <div v-else>
                  <strong class="is-size-8 is-pulled-right">0g</strong>
                </div>
                <br>
              <div class="is-size-7" v-if="configuration.components.options.gripsVariation">{{ configuration.components.options.gripsVariation.name }}</div>
            </div>
          </div>

          <div v-if="configuration.components.options.handlebarTape">
            <div class="gap" />
            <strong>Lenkerband</strong>
            <div>
              {{ configuration.components.options.handlebarTape.name }}
              <div class="is-pulled-right">{{ formatPrice(configuration.components.options.handlebarTape.customPrice) }}</div>
                <div v-if="configuration.components.options.handlebarTape.weight">
                  <strong class="is-pulled-right" :class="(configuration.components.options.handlebarTape.weight <= 0) ? 'has-text-primary' : 'is-negative'">
                    <span v-if="configuration.components.options.handlebarTape.weight >= 0">+</span>
                    {{ configuration.components.options.handlebarTape.weight.toLocaleString('de-DE') }}g
                  </strong>
                </div>
                <div v-else>
                  <strong class="is-size-8 is-pulled-right">0g</strong>
                </div>
                <br>
              <div class="is-size-7" v-if="configuration.components.options.handlebarTapeVariation">{{ configuration.components.options.handlebarTapeVariation.name }}</div>
            </div>
          </div>

          <div v-if="configuration.components.options.pedals">
            <div class="gap" />
            <strong>Pedale</strong>
            <div>
              {{ configuration.components.options.pedals.name }}
              <div class="is-pulled-right">{{ formatPrice(configuration.components.options.pedals.customPrice) }}</div>
                <div v-if="configuration.components.options.pedals.weight">
                  <strong class="is-pulled-right" :class="(configuration.components.options.pedals.weight <= 0) ? 'has-text-primary' : 'is-negative'">
                    <span v-if="configuration.components.options.pedals.weight >= 0">+</span>
                    {{ configuration.components.options.pedals.weight.toLocaleString('de-DE') }}g
                  </strong>
                </div>
                <div v-else>
                  <strong class="is-size-8 is-pulled-right">0g</strong>
                </div>
                <br>
              <div class="is-size-7" v-if="configuration.components.options.pedalsVariation">{{ configuration.components.options.pedalsVariation.name }}</div>
            </div>
          </div>

          <div v-if="configuration.components.options.saddle">
            <div class="gap" />
            <strong>Sattel</strong>
            <div>
              {{ configuration.components.options.saddle.name }}
              <div class="is-pulled-right">{{ formatPrice(configuration.components.options.saddle.customPrice) }}</div>
                <div v-if="configuration.components.options.saddle.weight">
                  <strong class="is-pulled-right" :class="(configuration.components.options.saddle.weight <= 0) ? 'has-text-primary' : 'is-negative'">
                    <span v-if="configuration.components.options.saddle.weight >= 0">+</span>
                    {{ configuration.components.options.saddle.weight.toLocaleString('de-DE') }}g
                  </strong>
                </div>
                <div v-else>
                  <strong class="is-size-8 is-pulled-right">0g</strong>
                </div>
                <br>
              <div class="is-size-7" v-if="configuration.components.options.saddleVariation">{{ configuration.components.options.saddleVariation.name }}</div>
            </div>
          </div>

          <div v-if="configuration.components.options.seatPost">
            <div class="gap" />
            <strong>Sattelstütze</strong>
            <div>
              {{ configuration.components.options.seatPost.name }}
              <div class="is-pulled-right">{{ formatPrice(configuration.components.options.seatPost.customPrice) }}</div>
                <div v-if="configuration.components.options.seatPost.weight">
                  <strong class="is-pulled-right" :class="(configuration.components.options.seatPost.weight <= 0) ? 'has-text-primary' : 'is-negative'">
                    <span v-if="configuration.components.options.seatPost.weight >= 0">+</span>
                    {{ configuration.components.options.seatPost.weight.toLocaleString('de-DE') }}g
                  </strong>
                </div>
                <div v-else>
                  <strong class="is-size-8 is-pulled-right">0g</strong>
                </div>
                <br>
              <div class="is-size-7" v-if="configuration.components.options.seatPostVariation">{{ configuration.components.options.seatPostVariation.name }}</div>
            </div>
          </div>
        </div>
        <div class="column is-4" v-if="configuration.components.accessoires">
          <strong class="has-text-primary is-size-6">Zubehör</strong>
          <div v-if="configuration.components.accessoires.stvoBasic">
            <div class="gap" />
            <strong>STVO Basisausstattung</strong>
            <div>
              {{ configuration.components.accessoires.stvoBasic.name }}
              <div class="is-pulled-right">{{ formatPrice(configuration.components.accessoires.stvoBasic.customPrice) }}</div>
                <div v-if="configuration.components.accessoires.stvoBasic.weight">
                  <strong class="is-pulled-right" :class="(configuration.components.accessoires.stvoBasic.weight <= 0) ? 'has-text-primary' : 'is-negative'">
                    <span v-if="configuration.components.accessoires.stvoBasic.weight >= 0">+</span>
                    {{ configuration.components.accessoires.stvoBasic.weight.toLocaleString('de-DE') }}g
                  </strong>
                </div>
                <div v-else>
                  <strong class="is-size-8 is-pulled-right">0g</strong>
                </div>
                <br>
            </div>
          </div>
          <div v-if="configuration.components.accessoires.mudguards">
            <div class="gap" />
            <strong>Schutzbleche</strong>
            <div>
              {{ configuration.components.accessoires.mudguards.name }}
              <div class="is-pulled-right">{{ formatPrice(configuration.components.accessoires.mudguards.customPrice) }}</div>
                <div v-if="configuration.components.accessoires.mudguards.weight">
                  <strong class="is-pulled-right" :class="(configuration.components.accessoires.mudguards.weight <= 0) ? 'has-text-primary' : 'is-negative'">
                    <span v-if="configuration.components.accessoires.mudguards.weight >= 0">+</span>
                    {{ configuration.components.accessoires.mudguards.weight.toLocaleString('de-DE') }}g
                  </strong>
                </div>
                <div v-else>
                  <strong class="is-size-8 is-pulled-right">0g</strong>
                </div>
                <br>
            </div>
          </div>
          <div v-if="configuration.components.accessoires.luggageCarrier">
            <div class="gap" />
            <strong>Gepäckträger</strong>
            <div>
              {{ configuration.components.accessoires.luggageCarrier.name }}
              <div class="is-pulled-right">{{ formatPrice(configuration.components.accessoires.luggageCarrier.customPrice) }}</div>
                <div v-if="configuration.components.accessoires.luggageCarrier.weight">
                  <strong class="is-pulled-right" :class="(configuration.components.accessoires.luggageCarrier.weight <= 0) ? 'has-text-primary' : 'is-negative'">
                    <span v-if="configuration.components.accessoires.luggageCarrier.weight >= 0">+</span>
                    {{ configuration.components.accessoires.luggageCarrier.weight.toLocaleString('de-DE') }}g
                  </strong>
                </div>
                <div v-else>
                  <strong class="is-size-8 is-pulled-right">0g</strong>
                </div>
                <br>
            </div>
          </div>
          <div v-if="configuration.components.accessoires.hubDynamo">
            <div class="gap" />
            <strong>Nabendynamo</strong>
            <div>
              {{ configuration.components.accessoires.hubDynamo.name }}
              <div class="is-pulled-right">{{ formatPrice(configuration.components.accessoires.hubDynamo.customPrice) }}</div>
                <div v-if="configuration.components.accessoires.hubDynamo.weight">
                  <strong class="is-pulled-right" :class="(configuration.components.accessoires.hubDynamo.weight <= 0) ? 'has-text-primary' : 'is-negative'">
                    <span v-if="configuration.components.accessoires.hubDynamo.weight >= 0">+</span>
                    {{ configuration.components.accessoires.hubDynamo.weight.toLocaleString('de-DE') }}g
                  </strong>
                </div>
                <div v-else>
                  <strong class="is-size-8 is-pulled-right">0g</strong>
                </div>
                <br>
            </div>
          </div>
          <div v-if="configuration.components.accessoires.headlights">
            <div class="gap" />
            <strong>Scheinwerfer</strong>
            <div>
              {{ configuration.components.accessoires.headlights.name }}
              <div class="is-pulled-right">{{ formatPrice(configuration.components.accessoires.headlights.customPrice) }}</div>
                <div v-if="configuration.components.accessoires.headlights.weight">
                  <strong class="is-pulled-right" :class="(configuration.components.accessoires.headlights.weight <= 0) ? 'has-text-primary' : 'is-negative'">
                    <span v-if="configuration.components.accessoires.headlights.weight >= 0">+</span>
                    {{ configuration.components.accessoires.headlights.weight.toLocaleString('de-DE') }}g
                  </strong>
                </div>
                <div v-else>
                  <strong class="is-size-8 is-pulled-right">0g</strong>
                </div>
                <br>
            </div>
            <div class="is-size-7" v-if="configuration.components.accessoires.headlightsVariation">{{ configuration.components.accessoires.headlightsVariation.name }}</div>
          </div>
          <div v-if="configuration.components.accessoires.backlights">
            <div class="gap" />
            <strong>Rücklicht</strong>
            <div>
              {{ configuration.components.accessoires.backlights.name }}
              <div class="is-pulled-right">{{ formatPrice(configuration.components.accessoires.backlights.customPrice) }}</div>
                <div v-if="configuration.components.accessoires.backlights.weight">
                  <strong class="is-pulled-right" :class="(configuration.components.accessoires.backlights.weight <= 0) ? 'has-text-primary' : 'is-negative'">
                    <span v-if="configuration.components.accessoires.backlights.weight >= 0">+</span>
                    {{ configuration.components.accessoires.backlights.weight.toLocaleString('de-DE') }}g
                  </strong>
                </div>
                <div v-else>
                  <strong class="is-size-8 is-pulled-right">0g</strong>
                </div>
                <br>
            </div>
          </div>
          <div v-if="configuration.components.accessoires.bell">
            <div class="gap" />
            <strong>Glocke</strong>
            <div>
              {{ configuration.components.accessoires.bell.name }}
              <div class="is-pulled-right">{{ formatPrice(configuration.components.accessoires.bell.customPrice) }}</div>
                <div v-if="configuration.components.accessoires.bell.weight">
                  <strong class="is-pulled-right" :class="(configuration.components.accessoires.bell.weight <= 0) ? 'has-text-primary' : 'is-negative'">
                    <span v-if="configuration.components.accessoires.bell.weight >= 0">+</span>
                    {{ configuration.components.accessoires.bell.weight.toLocaleString('de-DE') }}g
                  </strong>
                </div>
                <div v-else>
                  <strong class="is-size-8 is-pulled-right">0g</strong>
                </div>
                <br>
            </div>
          </div>
          <div v-if="configuration.components.accessoires.stand">
            <div class="gap" />
            <strong>Ständer</strong>
            <div>
              {{ configuration.components.accessoires.stand.name }}
              <div class="is-pulled-right">{{ formatPrice(configuration.components.accessoires.stand.customPrice) }}</div>
                <div v-if="configuration.components.accessoires.stand.weight">
                  <strong class="is-pulled-right" :class="(configuration.components.accessoires.stand.weight <= 0) ? 'has-text-primary' : 'is-negative'">
                    <span v-if="configuration.components.accessoires.stand.weight >= 0">+</span>
                    {{ configuration.components.accessoires.stand.weight.toLocaleString('de-DE') }}g
                  </strong>
                </div>
                <div v-else>
                  <strong class="is-size-8 is-pulled-right">0g</strong>
                </div>
                <br>
            </div>
          </div>
          <div v-if="configuration.components.accessoires.chainGuard">
            <div class="gap" />
            <strong>Kettenschutz</strong>
            <div>
              {{ configuration.components.accessoires.chainGuard.name }}
              <div class="is-pulled-right">{{ formatPrice(configuration.components.accessoires.chainGuard.customPrice) }}</div>
                <div v-if="configuration.components.accessoires.chainGuard.weight">
                  <strong class="is-pulled-right" :class="(configuration.components.accessoires.chainGuard.weight <= 0) ? 'has-text-primary' : 'is-negative'">
                    <span v-if="configuration.components.accessoires.chainGuard.weight >= 0">+</span>
                    {{ configuration.components.accessoires.chainGuard.weight.toLocaleString('de-DE') }}g
                  </strong>
                </div>
                <div v-else>
                  <strong class="is-size-8 is-pulled-right">0g</strong>
                </div>
                <br>
            </div>
          </div>
          <div v-if="configuration.components.accessoires.lock">
            <div class="gap" />
            <strong>Schloss</strong>
            <div>
              {{ configuration.components.accessoires.lock.name }}
              <div class="is-pulled-right">{{ formatPrice(configuration.components.accessoires.lock.customPrice) }}</div>
                <div v-if="configuration.components.accessoires.lock.weight">
                  <strong class="is-pulled-right" :class="(configuration.components.accessoires.lock.weight <= 0) ? 'has-text-primary' : 'is-negative'">
                    <span v-if="configuration.components.accessoires.lock.weight >= 0">+</span>
                    {{ configuration.components.accessoires.lock.weight.toLocaleString('de-DE') }}g
                  </strong>
                </div>
                <div v-else>
                  <strong class="is-size-8 is-pulled-right">0g</strong>
                </div>
                <br>
            </div>
            <div class="is-size-7" v-if="configuration.components.accessoires.lockVariation">{{ configuration.components.accessoires.lockVariation.name }}</div>
          </div>
          <div v-if="configuration.components.accessoires.bikeComputer">
            <div class="gap" />
            <strong>Fahrradcomputer</strong>
            <div>
              {{ configuration.components.accessoires.bikeComputer.name }}
              <div class="is-pulled-right">{{ formatPrice(configuration.components.accessoires.bikeComputer.customPrice) }}</div>
                <div v-if="configuration.components.accessoires.bikeComputer.weight">
                  <strong class="is-pulled-right" :class="(configuration.components.accessoires.bikeComputer.weight <= 0) ? 'has-text-primary' : 'is-negative'">
                    <span v-if="configuration.components.accessoires.bikeComputer.weight >= 0">+</span>
                    {{ configuration.components.accessoires.bikeComputer.weight.toLocaleString('de-DE') }}g
                  </strong>
                </div>
                <div v-else>
                  <strong class="is-size-8 is-pulled-right">0g</strong>
                </div>
                <br>
            </div>
          </div>
          <div v-if="configuration.components.accessoires.batteryLight">
            <div class="gap" />
            <strong>Batterielicht</strong>
            <div>
              {{ configuration.components.accessoires.batteryLight.name }}
              <div class="is-pulled-right">{{ formatPrice(configuration.components.accessoires.batteryLight.customPrice) }}</div>
                <div v-if="configuration.components.accessoires.batteryLight.weight">
                  <strong class="is-pulled-right" :class="(configuration.components.accessoires.batteryLight.weight <= 0) ? 'has-text-primary' : 'is-negative'">
                    <span v-if="configuration.components.accessoires.batteryLight.weight >= 0">+</span>
                    {{ configuration.components.accessoires.batteryLight.weight.toLocaleString('de-DE') }}g
                  </strong>
                </div>
                <div v-else>
                  <strong class="is-size-8 is-pulled-right">0g</strong>
                </div>
                <br>
            </div>
          </div>
          <div v-if="configuration.components.accessoires.panniers">
            <div class="gap" />
            <strong>Gepäcktasche</strong>
            <div>
              {{ configuration.components.accessoires.panniers.name }}
              <div class="is-pulled-right">{{ formatPrice(configuration.components.accessoires.panniers.customPrice) }}</div>
                <div v-if="configuration.components.accessoires.panniers.weight">
                  <strong class="is-pulled-right" :class="(configuration.components.accessoires.panniers.weight <= 0) ? 'has-text-primary' : 'is-negative'">
                    <span v-if="configuration.components.accessoires.panniers.weight >= 0">+</span>
                    {{ configuration.components.accessoires.panniers.weight.toLocaleString('de-DE') }}g
                  </strong>
                </div>
                <div v-else>
                  <strong class="is-size-8 is-pulled-right">0g</strong>
                </div>
                <br>
            </div>
            <div class="is-size-7" v-if="configuration.components.accessoires.panniersVariation">{{ configuration.components.accessoires.panniersVariation.name }}</div>
          </div>
          <div v-if="configuration.components.accessoires.bottleCage">
            <div class="gap" />
            <strong>Flaschenhalter</strong>
            <div>
              {{ configuration.components.accessoires.bottleCage.name }}
              <div class="is-pulled-right">{{ formatPrice(configuration.components.accessoires.bottleCage.customPrice) }}</div>
               <div v-if="configuration.components.accessoires.bottleCage.weight">
                  <strong class="is-pulled-right" :class="(configuration.components.accessoires.bottleCage.weight <= 0) ? 'has-text-primary' : 'is-negative'">
                    <span v-if="configuration.components.accessoires.bottleCage.weight >= 0">+</span>
                    {{ configuration.components.accessoires.bottleCage.weight.toLocaleString('de-DE') }}g
                  </strong>
                </div>
                <div v-else>
                  <strong class="is-size-8 is-pulled-right">0g</strong>
                </div>
                <br>
            </div>
            <div class="is-size-7" v-if="configuration.components.accessoires.bottleCageVariation">{{ configuration.components.accessoires.bottleCageVariation.name }}</div>
          </div>
        </div>
      </div>

      <div class="line" />

        <div class="columns">
          <div class="column is-4">
            <div v-if="configuration.model && configuration.model.weight">
              <div class="is-size-7 has-text-primary">Gesamtgewicht</div>
                <strong class="is-size-5 has-text-primary is-size-5-mobile">{{ totalWeight.toLocaleString('de-DE') }} g</strong>
            </div>
            <div class="is-size-5 has-text-primary">Gesamtpreis (brutto)</div>
            <strong class="is-size-1 has-text-primary is-size-3-mobile">{{ formatPrice(totalPrice) }}</strong>
            <div class="gap-2"/>
              <div v-if="!isPreLoadedConfiguration">
                <button class="button is-primary has-text-black is-fullwidth" @click="showOrderModal = true; sendTracking()">Modell anfragen</button>
            </div>
          </div>
        </div>

        <OrderModal
          v-if="!isPreLoadedConfiguration && showOrderModal"
          @close="showOrderModal = false"
        />

    </div>
    <div class="bottom-footer has-background-grey-darker" align="center">
      Velofaktum Fahrrad Konfigurator<br/>
      <a href="https://www.velofaktum.de/impressum">Impressum</a> | <a href="https://www.velofaktum.de/datenschutzerklaerung">Datenschutz</a> | <a href="https://www.velofaktum.de/agb">AGB</a>
      <br/><br/>
      Fahrrad Konfigurator realisiert von der <a href="https://www.pocket-rocket.io" target="_blank">Pocket Rocket GmbH</a><br/>&copy; Velofaktum
    </div>
  </div>
</template>

<script>
import OrderModal from '@/components/OrderModal'
export default {
  components: { OrderModal },
  data () {
    return {
      showOrderModal: false
    }
  },
  methods: {
    sendTracking() {
      this.$gtm.trackEvent({
        event: "form-submit",
        Modell: this.configuration.model.name,
        Preis: this.totalPrice,
        noninteraction: false
      });
    },
  },
  mounted() {
    this.$gtm.trackView("HomePage");
  },

  computed: {
    configuration () {
      return this.$store.getters.getCurrentConfiguration
    },
    totalPrice () {
      return this.$store.getters.getTotalPrice
    },
    totalWeight () {
      return this.$store.getters.getTotalWeight
    },
    isPreLoadedConfiguration () {
      return this.$store.state.isPreLoadedConfiguration
    }
  },
}
</script>

<style lang="sass" scoped>
@import "~@/assets/sass/utilities/initial-variables.sass"
.summary-container
  color: $white-ter
  padding: $base-gap * 4 $base-gap * 4
  background-color: $grey-darker
  strong
    color: $white-ter

  .line
    border-bottom: 3px solid $green
    width: 100%
    margin: $base-gap * 2 0

  .summary-top
    line-height: 2

.bottom-footer
  padding: $base-gap
  a
    color: $grey

.is-negative
  color: $red!important
  
</style>
