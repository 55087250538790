<template>
  <div class="choose-category" v-if="categories.length > 0 && currentCategory">
    <div class="gap" />
    <strong>Grundtyp</strong>
    <div class="gap" />
    <div class="custom-select is-clickable" @click="componentDropdownExpanded = !componentDropdownExpanded">
      {{ currentCategory.name }}
      <img src="@/assets/icons/chevron-down.svg" />
    </div>
    <div v-if="componentDropdownExpanded" class="custom-select expanded">
      <div v-for="(category, index) in categories" :key="index" :class="['product-name inner is-clickable', { 'is-active' : category === currentCategory}]" @click="currentCategory = category; componentDropdownExpanded = false">
        {{ category.name }}
      </div>
    </div>

  </div>
</template>

<script>
const categoryIdMain = 261

export default {
  data () {
    return {
      componentDropdownExpanded: false
    }
  },
  computed: {
    categories () {
      return this.$store.state.categories.filter(cat => cat.parent === categoryIdMain)
    },
  },
  mounted () {
    if (this.categories.length > 0 && !this.currentCategory) {
      this.currentCategory = this.categories[0]
    }
  },
}
</script>

<style lang="css" scoped>
</style>
