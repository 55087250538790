// // Axios Config
// var axios = require('axios')
//
// // TODO: Outsource in dotenv => https://www.npmjs.com/package/dotenv
//
// const domain = 'https://www.produkt-konfiguration.com'
// const consumerKey = 'ck_9f6f917893202a296307357f19bbd9b4be13fac2'
// const consumerSecret = 'cs_3206a481dc114385e17dc94b69f7a99413afe6df'
//
// var axiosInstance = axios.create({
//   baseURL: `${domain}/wp-json/wc/v3`,
//   params: {
//     per_page: 100,
//     consumer_key: consumerKey,
//     consumer_secret: consumerSecret
//   }
// });
//
// module.exports = axiosInstance

var axios = require('axios')
// TODO: Outsource in dotenv => https://www.npmjs.com/package/dotenv
// const domain = 'http://localhost:3000'
const domain = 'https://api.velofaktum.de'

var axiosInstance = axios.create({
  baseURL: `${domain}/v1`,
  headers: { 'Authorization': 'helloworld' }
});

module.exports = axiosInstance
