<template>
  <div class="modal is-active">
    <div class="modal-background" @click="emitClose()"></div>
    <div class="modal-content">
      <header class="modal-card-head">
        <p class="modal-card-title">Rahmenhöhe ermitteln</p>
        <button class="delete" aria-label="close" @click="emitClose()"></button>
      </header>
      <section class="modal-card-body footerless has-text-dark">
        <p>Gemessen wird die Rahmenhöhe von Mitte Tretlager bis Oberkante Sitzrohr (dort wo die Sattelstütze beginnt).
        Eine erste und in den meisten Fällen korrekte Bestimmung der optimalen Rahmenhöhe kann mittels Formel anhand der Schrittlänge (Innenbeinlänge) ermittelt werden.</p>
        <div class="gap" />
        <p><strong>1. Die richtige Schrittlänge ermitteln</strong></p>
        <p>Idealerweise verwenden Sie einen Zollstock und eine Wasserwaage. Es kann jedoch auch problemlos ein Buch benutzt werden.
        Die Messung sollte ohne Schuhe und ohne Hose durchgeführt werden.</p>
        <p>Stellen Sie sich aufrecht stehend an eine Wand o.ä. Ziehen sie die Wasserwaage oder das Buch möglichst waagerecht zwischen den Beinen nach oben. Messen Sie jetzt die Länge vom Boden bis zur Oberkante der Wasserwaage oder des Buches. Der so ermittelte Wert gibt Ihre Schrittlänge in cm an.</p>
        <div class="gap" />
        <p><strong>2. Den theoretischen Wert der Rahmenhöhe berechnen</strong></p>
        <p>Die tatsächlich optimale Rahmenhöhe kann je nach Körperproportionen (Arm- und Beinlänge) oder Einsatzzweck vom errechneten Wert abweichen und wird von uns immer anhand der Angaben im Bestellformular eines Rades überprüft und ggf. korrigiert. Liegen Sie zwischen zwei möglichen Rahmenhöhen, entscheiden wir je nach Körperproportionen welche Größe günstiger für Sie wäre.</p>

        <p>Passend zum gewählten Modell <strong>{{ this.currentModel.name }}</strong> haben wir folgende Formel ermittelt:</p>
        <div class="gap-2" />
        <p align="center" class="is-size-4">Schrittlänge in cm x {{ formulaFrameHeight }} = Rahmenhöhe in cm</p>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    formulaFrameHeight () {

      if (['110', '111', '120', '121', '122', '130', '140', '510', '520'].includes(this.currentModel.sku)) {
        return 0.574
      }

      if (['210', '220', '230', '240', '250', '530', '540', '550', '320', '330', '340', '350', '410', '420'].includes(this.currentModel.sku)) {
        return 0.665
      }
/**
      if ([].includes(this.currentModel.sku)) {
        return 0.574
      }
*/
      return 0
    }
  },
  methods: {
    emitClose () {
      this.$emit('close')
    },
  }
}
</script>

<style lang="css" scoped>
</style>
