<template>
  <div class="model-info-box has-text-grey is-size-6" v-if="product.hasInfoBoxOpen">
    <div class="delete is-clickable" @click="product.hasInfoBoxOpen = false" />
    <div v-html="product.description"/>
  </div>
</template>

<script>
export default {
  props: {
    product: Object,
  }
}
</script>

<style lang="sass" scoped>
@import "~@/assets/sass/utilities/initial-variables.sass"
.model-info-box
  width: 96%
  position: absolute
  top: 35px
  left: 2%
  border: 3px solid $grey
  padding: $base-gap $base-gap * 2.5 $base-gap $base-gap
  background-color: $white
  z-index: 100
  font-weight: normal

  .delete
    position: absolute
    top: 5px
    right: 5px
</style>
