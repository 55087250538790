<template>
  <div class="navigation-tabs">

    <div class="tabs">
      <ul>
        <li @click="tab = 'model'" :class="{ 'is-active' : tab === 'model'}">
          <div class="icon-box"><img src="@/assets/icons/bike.svg" /></div>
          <div>Modell</div>
        </li>
        <li @click="tab = 'basics'" :class="{ 'is-active' : tab === 'basics'}">
          <div class="icon-box"><img src="@/assets/icons/gear.svg" /></div>
          <div>Basics</div>
        </li>
        <li @click="tab = 'options'" :class="{ 'is-active' : tab === 'options'}">
          <div class="icon-box"><img src="@/assets/icons/colors.svg" /></div>
          <div>Optionen</div>
        </li>
        <li @click="tab = 'accessoires'" :class="{ 'is-active' : tab === 'accessoires'}">
          <div class="icon-box"><img src="@/assets/icons/plus.svg" /></div>
          <div>Zubehör</div>
        </li>
      </ul>
    </div>

    <div class="tab-container">

      <tab-model v-show="tab === 'model'" />
      <tab-basics v-show="tab === 'basics'" />
      <tab-options v-show="tab === 'options'" />
      <tab-accessoires v-show="tab === 'accessoires'" />

      <div align="center">
        <div class="gap-2" />
        <button class="button is-primary" v-if="tab === 'model'" @click="tab = 'basics';scrollToTop()">Weiter zu Basics</button>
        <button class="button is-primary" v-if="tab === 'basics'" @click="tab = 'options';scrollToTop()">Weiter zu Optionen</button>
        <button class="button is-primary" v-if="tab === 'options'" @click="tab = 'accessoires';scrollToTop()">Weiter zu Zubehör</button>
        <a class="button is-primary" v-if="tab === 'accessoires'" href="#summary">Zusammenfassung ansehen</a>
      </div>
    </div>
  </div>
</template>

<script>
import TabModel from '@/components/TabModel'
import TabBasics from '@/components/TabBasics'
import TabOptions from '@/components/TabOptions'
import TabAccessoires from '@/components/TabAccessoires'

export default {
  components: { TabModel, TabBasics, TabOptions, TabAccessoires },
  data () {
    return {
      tab: 'model'
    }
  }
}
</script>

<style lang="sass" scoped>
@import "~@/assets/sass/utilities/initial-variables.sass"
@import "~@/assets/sass/utilities/mixins.sass"

.navigation-tabs
  background: $white
  .tabs
    margin-bottom: 0
    width: 100%
    img
      width: 35px
      height: 35px
    +mobile
      img
        width: 30px
        height: 30px
  .tab-container
    padding: $base-gap * 3
</style>
