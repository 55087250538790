<template>
  <div>
    <div :data-tooltip="tooltip(color)" v-for="(color, index) in colors" :key="index" @click="setColor(color)" :class="['color-box has-tooltip-left', { 'is-active' : color.colors.hexCode == activeColor.hexCode }]" :style="{ background: color.colors.hexCode}">
      <span :style="{ color: getColorByBgColor(color.colors.hexCode) }" class="tick" v-if="color.colors.hexCode == activeColor.hexCode">✓</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    colors: {
      type: Array,
      required: true
    },
    activeColor: Object
  },
  methods: {
    setColor (color) {
      this.$emit('setColor', color)
    },
    tooltip (color) {
      let ralCode = ''
      let customPrice = ''

      if (color.colors.ralCode) {
        ralCode = ` | ${color.colors.ralCode}`
      }
      if (color.customPrice) {
        customPrice = ` | + ${this.formatPrice(color.customPrice)}`
      }

      return `${color.name}${ralCode}${customPrice}`
    }
  }
}
</script>

<style lang="sass" scoped>
[data-tooltip]:not(.is-disabled), [data-tooltip]:not(.is-loading), [data-tooltip]:not([disabled])
  z-index: 1000
</style>
