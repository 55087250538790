<template>
  <nav class="navbar" role="navigation" aria-label="main navigation">
    <div class="navbar-brand">
      <a class="navbar-item" href="https://www.velofaktum.de">
        <img src="@/assets/icons/logo.svg" alt="Velofaktum Konfigurator" height="60">
      </a>
    </div>
    <div class="navbar-end is-hidden-mobile">
      <a class="navbar-item" href="https://www.velofaktum.de">
        zurück zur Webseite
      </a>
    </div>
  </nav>
</template>

<script>
export default {
}
</script>

<style lang="css" scoped>
</style>
